//Универсальная галерея
class UniversalGalleryComponent {
    constructor(options) {
        this.elem = options.elem;
        this.swipe = this.elem.find('.ct-ug-root').Swipe({
           speed: 700
        }).data('Swipe');

        //Клик по контролу
        this.elem.click((event) => {
            let control = $(event.target).closest(".ct-ug-control");
            if (control.length && this.elem[0].contains(event.target)) {
                if (control.hasClass("__prev")) {
                    this.movePrev();
                } else {
                    this.moveNext();
                }
            }
        });
    }

    movePrev() {
        this.swipe.prev();
    }

    moveNext() {
        this.swipe.next();
    }
}

$(document).on('page:loaded', () => {
    $(".universal-gallery-component")
        .checkComponentElem('universal-gallery-component-initialized')
        .each((index, elem) => {
            new UniversalGalleryComponent({
                elem: $(elem)
            });
        });
});