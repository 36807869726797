import Vue from 'vue'

Vue.prototype.$getItems = async function (url, config = {}, showHideFooter = true) {
  //Футер всегда находится за пределами вью, приходится с ним работать через дом
  let footer = $('footer.main')
  //Страхуемся от несрабатывания сторонней директивы инф скрола
  if (this.busy) return Promise.resolve()
  this.busy = true
  this.allLoaded = false
  if (showHideFooter) footer.hide()

  try {
    let {data} = await axios.get(url, config)
    this.items = this.items.concat(data)

    this.busy = false
    this.page += 1

    //Если все загрузили
    if (!data.length) {
      this.busy = this.allLoaded = true
      if (showHideFooter) footer.show()
    }
  } catch (e) {
    this.$showError(e)
  }

  this.loaded = true
}
