class YandexMapComponent {
    constructor(options) {
        this.elem = options.elem;

        $(document).on("ym:ready", () => {
            this.initMap();
        });

        //Если инициализируем компонент уже после того как отработал коллбек готовности яндекс карт
        if(window.ym_init_fired) {
            this.initMap();
        }
    }

    initMap() {
        if(this.mapInited) {
            return;
        }

        this.coords = [this.elem.data("lat"), this.elem.data("long")];

        this.map = new ymaps.Map(this.elem[0], {
            center: this.coords,
            zoom: this.elem.data("zoom"),
            controls: ['fullscreenControl', 'zoomControl']
        });
        this.map.behaviors.disable('scrollZoom');

        this.placemark = new ymaps.Placemark(this.coords, {
            hintContent: ''
        }, {
            preset: 'islands#icon',
            iconColor: '#ff3a3a'
        });
        this.map.geoObjects.add(this.placemark);

        this.mapInited = true;
    }
}

$(document).on("page:loaded", () => {
    $(".yandex-map-component")
        .checkComponentElem("yandex-map-component-initialized")
        .each(function () {
            new YandexMapComponent({
                elem: $(this)
            });
        });
});