let liInited = false

class LiveinternetComponent {
  constructor(options) {
    //Одновременно на странице может работать только один компонент
    if (liInited) return
    liInited = true

    this.elem = options.elem
    //Бывают групповые счетчики в которых стандартный способ LI не подходит
    this.hitParam = this.elem.data('hit')
    this.hittedUrls = []

    //Это событие генерируем компонент автодоскрола - изменился УРЛ страницы
    $(document).on("aps:url_change", this.hit.bind(this))

    //Когда мы используем этот компонент, то сам код LI мы не выводим (он не зависит от домена).
    //мы тут сразу при инициализации первый хит делаем сами. Это особенность верстки - если вывести код LI
    //там где он должен быть, то возникают глюки
    this.hit()
  }

  hit() {
    let url = location.href
    if (this.hittedUrls.indexOf(url) !== -1) {
      return
    }

    if (this.hitParam) {
      new Image().src = `//counter.yadro.ru/${this.hitParam}` +
        escape(document.referrer) + ((typeof(screen) == "undefined") ? "" :
          ";s" + screen.width + "*" + screen.height + "*" + (screen.colorDepth ?
          screen.colorDepth : screen.pixelDepth)) + ";u" + escape(document.URL) +
        ";" + Math.random()
    } else {
      new Image().src = "//counter.yadro.ru/hit?r" +
        escape(document.referrer) + ((typeof(screen) == "undefined") ? "" :
          ";s" + screen.width + "*" + screen.height + "*" + (screen.colorDepth ?
          screen.colorDepth : screen.pixelDepth)) + ";u" + escape(document.URL) +
        ";" + Math.random()
    }

    this.hittedUrls.push(url)
  }
}

$(document).on("page:loaded", () => {
  $(".liveinternet-component")
    .checkComponentElem('li-inited')
    .each((index, elem) => {
      try {
        new LiveinternetComponent({
          elem: $(elem),
        })
      } catch (e) {
        console.error(e.message)
      }
    })
})