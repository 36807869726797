import Vue from 'vue'
import '#/helpers/check-component'

import mountComponent from '#/helpers/mount-component'
import mountApp from '#/vue-helpers/mount-app'

import Icon from '#/vue-components/icon'
import Loader from '#/vue-components/loader'

Vue.prototype.$desktop = true
Vue.prototype.$desktopClass = '__desktop'

Vue.component('icon', Icon)
Vue.component('loader', Loader)

import '@/_all_index'
import '@/_form_with_captcha'
import '@/_landing'
import '@/_photo_video'
import '@/_search_form'

import '@/_films'
import '@/_handlebars_helpers'
import '@/_main'
import '@/_places_category2'
import '@/_search_index'

import '@/_films_cinema'
import '@/_iframe'
import '@/_people_index'
import '@/_places_show'

import '@/_films_index'
import '@/_index'
import '@/_people_legends'
import '@/_profile_edit'

import '@/_films_movie'
import '@/_item_opinions'
import '@/_people_show'
import '@/_profile_index'


import '@/components/_adjust_background_position'
import '@/components/_liveinternet'
import '@/components/_rate'
import '@/components/_title_and_menu'

import '@/components/_ajax_load'
import '@/components/_lottery'
import '@/components/_rating2'
import '@/components/_universal_gallery'

import '@/components/_central_place'
import '@/components/_minis_gallery'
import '@/components/_select-tabs'
import '@/components/_url_control'

import '@/components/_events_tile'
import '@/components/_misclick'
import '@/components/_share'
import '@/components/_wysiwyg'

import '@/components/_expand'
import '@/components/_modal'
import '@/components/_share_on_select'
import '@/components/_yandex_map'

import '@/components/_fullscreen_image'
import '@/components/_movies_tile'
import '@/components/_social_modal'
import '@/components/_yandex_metrika'

import '@/components/_gallery'
import '@/components/_opinion'
import '@/components/_sticky-banner'
import '@/components/_youtube_thumb'

import '@/components/_google_analytics'
import '@/components/_opinions_filter'
import '@/components/_subscribe'

import '@/components/_infinity_load'
import '@/components/_overviews_tile'
import '@/components/_swipebox'

import '@/components/_infinity_scroll'
import '@/components/_places_tile'
import '@/components/_tabs'

import '@/different/_back_to_top'
import '@/different/_detect_safari'
import '@/different/_main_alerts'
import '@/different/_nav_lk'
import '@/different/_super_events'
import '@/different/_yandex_map_init'

// let components = [
//   {selector: '.theme-of-day-component', path: 'common/vue-components/theme-of-day'},
// ]
// components.forEach(elem => mountComponent(elem.selector, elem.path))

let apps = [
  // {selector: '.theme-of-day-component', path: 'common/vue-components/theme-of-day.vue'},
  {selector: '.index-theme', path: 'desktop/vue-apps/index-theme/app.vue'},
  {selector: '.index-events', path: 'desktop/vue-apps/index-events/app.vue'},
  {selector: '.index-news', path: 'desktop/vue-apps/index-news/app.vue'},
  {selector: '.top-search-form', path: 'desktop/vue-apps/search-form/app.vue'},
]
apps.forEach(elem => mountApp(elem.selector, elem.path))
