$.fn.checkComponentElem = function () {
  let elem = this
  elem = elem
  //Есть компоненты, которые не надо инициализировать автоматически
    .not('.__no-init')
    //Уже инициализированные компоненты повторное не инициализируем
    .not('.__initialized')
    //После инициализации добавляем компоненту класс initialized
    .addClass('__initialized')
  return elem
}
