class FullscreenImageComponent {
    constructor(options) {
        let obj = this;
        this.elem = options.elem;

        this.bg = this.elem.find(".fullscreen-image-component-ct-fi-bg");
        this.modal = this.elem.find(".fullscreen-image-component-ct-fi-modal");
        $("body").append(this.modal.add(this.bg));

        this.modal.append(`<img data-interchange="${this.elem.data('interchange')}">`);
        this.modal.foundation('interchange', 'reflow');

        this.elem.click(function (event) {
            if ($(event.target).closest(".ct-fi-open").length
                && $.contains(obj.elem[0], event.target)) {

                event.preventDefault();
                obj.showModal();
            }
        });

        this.modal.add(this.bg).click(function (event) {
            event.preventDefault();
            obj.closeModal();
        });
    }

    showModal() {
        let obj = this;

        obj.modal.css({
            top: (pageYOffset) + "px",
            height: (document.documentElement.clientHeight) + "px"
        });
        obj.modal.add(obj.bg).fadeIn();
    }

    closeModal() {
        let obj = this;

        obj.modal.add(obj.bg).fadeOut();
    }
}

$(document).on("page:loaded", () => {
    $(".fullscreen-image-component")
        .checkComponentElem("fullscreen-image-component-initialized")
        .each(function () {
            new FullscreenImageComponent({
                elem: $(this)
            });
        });
});