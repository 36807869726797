import Vue from 'vue'
import '#/vue-helpers/get-items'
// import '#/vue-helpers/get-item'
import '#/vue-helpers/api'
import '#/vue-helpers/show-error'

export default async function (selector, path) {
  // Возможно стоит использовать turbolinks
  // $(document).on('turbolinks:load', async () => {
  $(document).on('ready', async () => {
    let el = document.querySelector(selector)
    if (!el) return
    let app = await import(`../../${path}`)
    new Vue({el, render: c => c(app.default)})
  })
}
