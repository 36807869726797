class FormWithCaptcha {
  constructor(formElement) {
    this.form = formElement;
    this.htmlElem = $('html');

    // Передотвращаем повторную инициализацию капчи на данной форме
    if (this.form.hasClass('form-with-captcha-protected')) return;

    //Когда загрузится АПИ рекапчи, инициализируем ее
    if(this.htmlElem.data('recaptcha-loaded')) {
      this.addRecaptcha();
    }

    this.htmlElem.on('recaptcha-loaded', this.addRecaptcha.bind(this));

    // Устанавливаем класс .form-with-captcha-protected, указывающий на то, что капча уже инициализирована
    // на данной форме
    this.form.addClass('form-with-captcha-protected')
  }

  addRecaptcha() {
    grecaptcha.render(this.form.find('.form-with-captcha-submit')[0], {
      sitekey: this.htmlElem.data('sitekey'),
      badge: 'inline',
      size: 'invisible',
      callback: () => {
        this.form
          .data('recaptcha-fired', true)
          .trigger('submit');
      }
    });
  }
}

$(document).on('page:loaded', () => {
  $('.form-with-captcha')
    .each((index, elem) => {
      new FormWithCaptcha($(elem));
    });
});
