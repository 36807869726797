$(document).on("ready", function() {
    if (!$("section.main.films.cinema").length) {
        return;
    }

    //Карта
    class Map {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;
            this.coords = [this.elem.data("lat"), this.elem.data("long")]

            this.map = new ymaps.Map(this.elem.attr("id"), {
                center: this.coords,
                zoom: this.elem.data("zoom"),
                controls: ["zoomControl"]
            });

            this.fullscreenControl = new ymaps.control.FullscreenControl();
            this.map.controls.add(this.fullscreenControl);

            this.map.behaviors.disable('scrollZoom');

            this.placemark = new ymaps.Placemark(this.coords, {
                hintContent: ''
            }, {
                preset: 'islands#icon',
                iconColor: '#ff3a3a'
            });
            this.map.geoObjects.add(this.placemark);

            //Когда модальное окно откроетя, карту нужно по размеру контейнера отмасштабировать
            $(document).on('opened.fndtn.reveal', '[data-reveal]', function () {
                obj.map.container.fitToViewport();
            });


        }

        enterFullscreen() {
            this.fullscreenControl.enterFullscreen();
        }
    }

    $(document).on("ym:ready", () => {
        $("#films-cinema-map")
            .add("#films-cinema-map-in-modal")
            .each(function () {
                new Map({
                    elem: $(this)
                });
            });
    });

    //Галерея отзывов
    class ReviewsGallery {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;
            this.rootElem = this.elem.find(".root");

            this.rootElem.on("jcarousel:reload jcarousel:create", function () {
                let carousel = $(this);
                let width = carousel.innerWidth();

                if (width > 700) {
                    width = width / 2;
                }

                carousel.jcarousel("items").css("width", `${width}px`);
            }).jcarousel({
                wrap: 'circular',
                transitions: true,
                animation: 800
            });

            this.elem.find(".control.left").jcarouselControl({
                target: "-=1"
            });

            this.elem.find(".control.right").jcarouselControl({
                target: "+=1"
            });

        }
    }

    new ReviewsGallery({
        elem: $("section.main.films.cinema")
    });
});