$(document).on('page:loaded', () => {
    let elems = $('.swipebox-component');
    elems.each((index, elem) => {
        if ($(elem).attr('rel')) {
            return;
        }

        $(elem).attr('rel', `gallery_${Math.random()}`)
    });

    elems.swipebox({
        removeBarsOnMobile: false,
        hideBarsDelay: 1000000,
        //У swipebox проблемы на тач устройствах с закрытием https://github.com/brutaldesign/swipebox/issues/331
        afterOpen() {
            let selectorClose = $("#swipebox-close");
            let clickAction = "touchend click";

            selectorClose.unbind(clickAction);

            selectorClose.bind(clickAction, (event) => {
                event.preventDefault();
                event.stopPropagation();

                $.swipebox.close();
            });
        }
    });
});