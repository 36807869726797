class OpinionComponent {
    constructor(options) {
        this.elem = options.elem;

        this.elem.click((event) => {
            if ($(event.target).closest('.ct-on-controls-item.__share').length &&
                this.elem[0].contains(event.target)) {
                this.toggleSharePanel();
            }
        });

        this.elem.click((event) => {
            let controlElem = $(event.target).closest('.ct-on-controls-item:not(.__share, .__dont-process-click)');
            if (controlElem.length && this.elem[0].contains(event.target)) {
                controlElem.siblings('.ct-on-controls-item:not(.__share)').addClass('__dont-process-click');
                this.processControlClick(controlElem);
            }
        });
    }

    toggleSharePanel() {
        this.elem.find('.ct-on-controls-item.__share').toggleClass('__active');
        this.elem.find('.share-component').slideToggle();
    }

    processControlClick(controlElem) {
        controlElem.addClass('__dont-process-click');
        $.ajax({
            url: controlElem.data('url'),
            method: controlElem.data('method') || 'get'
        }).done(() => {
            controlElem.addClass('__clicked');
            let num = parseInt(controlElem.find('.ct-on-controls-text').text());
            num++;
            controlElem.find('.ct-on-controls-text').html(num.toString());
        });
    }
}

$(document).on('page:loaded', () => {
    $('.opinion-component')
        .checkComponentElem('opinion-component-initialized')
        .each((index, elem) => {
            new OpinionComponent({
                elem: $(elem)
            });
        });
});