class Lottery {
    constructor(options) {
        let obj = this;
        this.elem = options.elem;

        //Инициализируем блок шары
        this.elem.find(".share").each(function () {
            Ya.share2(this, {
                theme: {
                    services: "vkontakte,facebook,odnoklassniki,twitter",
                    counter: true
                },
                hooks: {
                    onshare: function (name) {
                        obj.triggerContentInModal(name);
                        obj.sendRequest();
                    }
                }
            });
        });
    }

    triggerContentInModal() {
        this.elem.find(".step-1 .loader").show();
        this.elem.find(".step-1 .error").hide();

        setTimeout(() => {
            if (this.responseOk) {
                this.elem.find(".step-1").fadeOut(400, () => {
                    this.elem.find(".step-2").fadeIn();
                });
            } else {
                this.elem.find(".step-1 .loader").hide();
                this.elem.find(".step-1 .error").show();
            }
        }, 10000);
    }

    sendRequest() {
        this.elem.find(".step-1 form").ajaxSubmit(() => {
            this.responseOk = true;
        });
    }
}

$(document).on("page:loaded", function () {
    $(".lottery-component")
        .checkComponentElem("lottery-component-initialized")
        .each(function () {
            new Lottery({
                elem: $(this)
            });
        });
});