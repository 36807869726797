class InfinityScroll {
    constructor(options) {
        this.elem = options.elem;
        this.loaderElem = this.elem.find(".ct-is-loader");
        this.loaderElem.addClass("__visible").remove();
        this.footerElem = $("footer.main");
        this.footerElem.hide();

        this.inProgress = false;
        this.onLastPage = false;
        //Счетчик запросов
        this.counter = 0;

        $(window).on("scroll resize", () => {
            if (this.inProgress || this.onLastPage) {
                return;
            }

            let delta = this.getScrollHeight() - pageYOffset - document.documentElement.clientHeight;

            //Подгрузка заранее - пока еще не дошли до конца страницы
            if (delta <= 4000) {
                this.processScroll();
            }
        });

        $(window).scroll();
    }

    processScroll() {
        let link = this.elem.find(".ct-is-pagination a.next_page");

        //Если нету у нас больше следующих страниц, то завершаем все
        if (!link.length || this.counter >= 3) {
            this.onLastPage = true;
            this.footerElem.show();
            return;
        }

        let url = link.attr("href");

        //Прошлую пагинацию удаляем. Она больше не нужна
        this.elem.find(".ct-is-pagination").remove();

        //Отправляем запрос на сервер за пачкой контента
        this.inProgress = true;
        this.elem.append(this.loaderElem);

        $.get(url, (data) => {
            this.inProgress = false;
            this.loaderElem.remove();

            this.elem.append(data);
            $(document).trigger('page:loaded');
            this.elem.trigger('is:loaded');

            //После загрузки контента сгенерим событие скролл, потому что может прийти мало данных и скроллбара не будет
            //а еще контент на сервере будет и его не смогут загрузить
            setTimeout(() => {
                $(window).scroll();
            }, 100);
        });

        //Увеличиваем счетчик запросов
        this.counter++;
    }

    getScrollHeight() {
        return Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight
        );
    }
}

$(document).on("page:loaded", function () {
    $(".infinity-scroll-component")
        .checkComponentElem("infinity-scroll-component-initialized")
        .each(function () {
            new InfinityScroll({
                elem: $(this)
            });
        });
});