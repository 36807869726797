$(document).on("ready", () => {
    //Показ результатов
    class ShowResults {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;
            this.showMoreElem = this.elem.find(".show-more");

            //Пагинация при отправке запроса
            this.page = 1;
            this.limit = this.elem.data("limit");

            //Город
            this.cityId = this.elem.data("city-id");

            //Первоначальное значение сортировки
            this.sortBy = this.elem.data("sort");

            //В режиме тестирования отправляем гетом на тестовый адрес, чтобы получить json
            if (this.elem.data("test-mode") == "yes") {
                this.testMode = true;
            } else {
                this.testMode = false;
            }

            //Шаблоны для вывода результатов
            this.basicResultTemplate = $("#all-basic-result-template").html();
            this.basicResultTemplate = Handlebars.compile(this.basicResultTemplate);

            //Отправка запроса - Показать больше
            this.elem.click(function (event) {
                if ($(event.target).closest(".show-more").length &&
                    obj.elem[0].contains(event.target)) {
                    event.preventDefault();
                    if (obj.requestInProgress) return;

                    obj.sendRequest(obj.compileParams(), false);
                }
            });

            //При первоначальной загрузке отправим запрос
            obj.sendRequest(obj.compileParams(), true);
        }

        compileParams() {
            let obj = this;

            let compiledParams = {
                common: {
                    city_id: obj.cityId,
                    page: obj.page,
                    limit: obj.limit,
                    sort_by: obj.sortBy
                }
            };

            //Готово. Возвращаем
            return compiledParams;
        }

        sendRequest(params, firstPage = true) {
            let obj = this;
            let url = obj.elem.data("results-url");
            obj.elem.addClass("disabled");
            obj.requestInProgress = true;

            if (obj.testMode) {
                $.get(url, "id=1", function (data) {
                    obj.processResponse(data, firstPage);
                });
            } else {
                //TODO обработку ошибок добавить
                $.ajax({
                    contentType: "application/json",
                    data: JSON.stringify(params),
                    method: "POST",
                    success: function (data) {
                        obj.processResponse(data, firstPage);
                    },
                    url: url
                });
            }
        }

        processResponse(data, firstPage) {
            let obj = this;
            let resultsContainer = obj.elem.find(".items");

            if (firstPage) {
                resultsContainer.html("");
            }

            data.forEach(function (item) {
                let templateData = {};

                switch (item.type) {
                    case "article":
                        templateData.date = item.data.human_created_at;
                        templateData.name = item.data.title;
                        templateData.url = item.data.url;

                        break;

                    case "event":
                        if (item.data.event_category) {
                            templateData.type = item.data.event_category.title;
                            templateData.type_icon = "e-p-types-component " + item.data.event_category.icon;
                            templateData.type_url = item.data.event_category.url;
                        }
                        templateData.date = item.data.human_occur_at;
                        if (item.data.place) {
                            templateData.place = item.data.place.title;
                            templateData.place_url = item.data.place.url;
                        }
                        templateData.name = item.data.title;
                        templateData.url = item.data.url;
                        if (item.data.city_area) {
                            templateData.location = item.data.city_area.title;
                            templateData.location_url = item.data.city_area.url;
                        }
                        templateData.r_v_l = item.data.rates || item.data.views;
                        templateData.rating = item.data.rates;
                        templateData.views = item.data.views;
                        templateData.tags = item.data.event_tags;


                        break;

                    case "place":
                        if (item.data.place_category) {
                            templateData.type = item.data.place_category.title;
                            templateData.type_url = item.data.place_category.url;
                            templateData.type_icon = "e-p-types-component " + item.data.place_category.icon;
                        }
                        templateData.name = item.data.title;
                        templateData.url = item.data.url;
                        templateData.location = item.data.address;
                        templateData.r_v_l = item.data.rates || item.data.views;
                        templateData.rating = item.data.rates;
                        templateData.views = item.data.views;
                        templateData.tags = item.data.place_tags;

                        break;

                    case "benefit":
                        let benefit_type, benefit_icon;
                        if (item.data.kind == 1) {
                            benefit_type = "Купон";
                            benefit_icon = "benefit-kupon";
                        } else if (item.data.kind == 2) {
                            benefit_type = "Скида";
                            benefit_icon = "benefit-discount";
                        } else if (item.data.kind == 3) {
                            benefit_type = "Подарок";
                            benefit_icon = "benefit-gift";
                        }

                        templateData.type = benefit_type;
                        templateData.type_icon = benefit_icon;
                        templateData.type_url = item.data.kind_url;
                        templateData.date = item.data.human_finish_at;
                        if (item.data.place) {
                            templateData.place = item.data.place.title;
                            templateData.place_url = item.data.place.url;
                        }
                        templateData.name = item.data.title;
                        templateData.url = item.data.url;
                        templateData.tags = item.data.benefit_tags;


                        break;

                    case "movie":
                        templateData.name = item.data.title;
                        templateData.url = item.data.url;
                        templateData.views = item.data.views;
                        templateData.tags = item.data.movie_tags;
                        templateData.r_v_l = item.data.views;
                        templateData.views = item.data.views;
                        templateData.video_code = item.data.embed_code;
                        templateData.video_id = item.data.id;

                        break;

                    case "album":
                        templateData.name = item.data.title;
                        templateData.url = item.data.url;
                        templateData.views = item.data.views;
                        templateData.tags = item.data.album_tags;
                        templateData.r_v_l = item.data.views;
                        templateData.views = item.data.views;

                        if(item.data.photos) {
                            templateData.custom_text = `${item.data.photos.length} фото`;
                        }

                        break;
                }

                resultsContainer.append(obj.basicResultTemplate(templateData));
            });

            obj.elem.removeClass("disabled");
            obj.requestInProgress = false;

            //Пагинация. Если результатов столькое же сколько limit, значит текущая страница не последняя
            //надо кнопку Показать больше вывести и page увеличить
            if (data.length >= obj.limit) {
                obj.page += 1;
                obj.showMoreElem.addClass("active");
            } else {
                obj.showMoreElem.removeClass("active");
            }
        }
    }

    $("section.main.all.index")
        .checkComponentElem("section-main-all-initialized")
        .each(function () {
            new ShowResults({
                elem: $(this)
            });
        });
});