class OpinionsFilterComponent {
    constructor(options) {
        this.elem = options.elem;
        this.nameInputContainerElem = this.elem.find('.ct-fc-name-container');
        this.nameInputElem = this.nameInputContainerElem.find('input.ct-fc-name');

        //Туглим ДД
        this.elem.click((event) => {
            let ddTrigger = $(event.target).closest('.ct-fc-dd-trigger');
            if (ddTrigger.length && this.elem[0].contains(event.target)) {
                this.toggleDD(ddTrigger.closest('.ct-fc-dd'));
            }
        });

        //Выбор элемента ДД
        this.elem.click((event) => {
            let ddItemElem = $(event.target).closest('.ct-fc-dd li');
            if (ddItemElem.length && this.elem[0].contains(event.target)) {
                this.selectDDItem(ddItemElem);
            }
        });

        //Закрываем ДД по клику вне его
        this.elem.find('.ct-fc-dd').each((index, elem) => {
            this.setOutclick($(elem));
        });

        //Ввод названия
        this.nameInputElem.on('input', () => {
            if (this.ajaxTimeoutId) {
                clearTimeout(this.ajaxTimeoutId);
            }

            this.ajaxTimeoutId = setTimeout((this.getNameVariants.bind(this)), 400);
        });

        //Фокус на названии
        this.nameInputElem.focus(() => {
            this.nameInputContainerElem.addClass('__active');
        });

        //Уход с названия
        $(document).click((event) => {
            if (this.nameInputContainerElem[0].contains(event.target)) {
                return;
            }

            this.nameInputContainerElem.removeClass('__active');
        });

        //Выбор вариант названия
        this.elem.click((event) => {
            let nameItemElem = $(event.target).closest('.ct-fc-name-container li');
            if (nameItemElem.length && this.elem[0].contains(event.target)) {
                this.processNameItemElemClick(nameItemElem);
            }
        });

        //Не даем отправляться форме на нажатию Enter в поле с названием
        this.nameInputElem.on('keydown', (event) => {
           if(event.keyCode == 13) {
               event.preventDefault();
           }
        });

        //Если форма рассчитана на аякс
        if(this.elem.hasClass('__remote')) {
            this.elem.ajaxForm({
                beforeSubmit: () => {
                    $(this.elem.data('response-container'))
                        .add(this.elem.find('button'))
                        .addClass('c-pointer-events-none c-opacity-50');
                },
                success: (data) => {
                    this.elem.find('button').removeClass('c-pointer-events-none c-opacity-50');
                    $(this.elem.data('response-container'))
                        .removeClass('c-pointer-events-none c-opacity-50')
                        .html(data);
                    $(document).trigger('page:loaded');
                }
            })
        }
    }

    processNameItemElemClick(nameItemELem) {
        this.nameInputContainerElem.find('input.value').val(nameItemELem.data('value'));
        this.nameInputElem.val($.trim(nameItemELem.text()));
        this.nameInputContainerElem.removeClass('__active');
    }

    getNameVariants() {
        //Сбросим значение названия (скрытого поля)
        let inputContainerElem = this.elem.find('.ct-fc-name-container');
        inputContainerElem.find('input.value').val(null);

        $.ajax({
            url: inputContainerElem.data('autocomplete-url'),
            method: inputContainerElem.data('method'),
            data: {
                [inputContainerElem.data('param-name')]: inputContainerElem.find('.ct-fc-name').val()
            }
        }).done((data) => {
            inputContainerElem.find('ul').html(data);
        }).fail((xhr) => {
            console.log(`При выполнении запроса произошла ошибка ${xhr.responseText}`);
        });
    }

    setOutclick(ddELem) {
        //Закрытие ДД по клику вне его
        $(document).click((event) => {
            if (ddELem[0].contains(event.target)) {
                return;
            }

            this.closeDD(ddELem);
        });
    }

    toggleDD(ddElem) {
        ddElem.toggleClass('__active');
    }

    closeDD(ddElem) {
        ddElem.removeClass('__active');
    }

    selectDDItem(ddItemElem) {
        let ddElem = ddItemElem.closest('.ct-fc-dd');
        if (ddItemElem.hasClass('__reset')) {
            ddElem.find('.ct-fc-dd-trigger span').text(ddItemElem.data('text'));
            ddElem.find('.ct-fc-dd-trigger input.value').val(null);
            this.closeDD(ddElem);
            return;
        }

        ddElem.find('.ct-fc-dd-trigger span').text(ddItemElem.text());
        ddElem.find('.ct-fc-dd-trigger input.value').val(ddItemElem.data('value'));
        this.closeDD(ddElem);
    }
}

$(document).on('page:loaded', () => {
    $('.opinions-filter-component')
        .checkComponentElem('opinions-filter-component-initialized')
        .each((index, elem) => {
            new OpinionsFilterComponent({
                elem: $(elem)
            });
        });
});