class TabsComponent {
    constructor(options) {
        let obj = this;
        this.elem = options.elem;

        this.elem.click(function (event) {
            let tab = $(event.target).closest(".ct-tab");
            if (tab.length && obj.elem[0].contains(event.target)) {
                obj.showTab(tab);
            }
        });
    }

    showTab(tabElem) {
        let tabContent = $("#" + tabElem.data("id"));
        let tabContentContainer = tabContent.closest(".tabs-component-content");

        //Если у выбранного элемента уже есть класс __active значит он уже и так
        //виден и ничего делать не надо
        if (tabContent.hasClass("__active")) {
            return;
        }

        //Сейчас компонент работает с показом с анимацией
        tabContentContainer.find(".ct-tab-content").removeClass("__active").hide();
        tabContent.fadeIn(500, function () {
            tabContent.addClass("__active");
        });
    }
}

$(document).on("page:loaded", () => {
    $(".tabs-component-tabs")
        .checkComponentElem("tabs-component-tabs-initialized")
        .each(function () {
            new TabsComponent({
                elem: $(this)
            });
        });
});
