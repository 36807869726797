$(document).on("ready", function () {
    if (!$("section.main.places.show").length) {
        return;
    }

    //Карта
    class Map {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;
            this.coords = [this.elem.data("lat"), this.elem.data("long")]

            this.map = new ymaps.Map(this.elem.attr("id"), {
                center: this.coords,
                zoom: this.elem.data("zoom"),
                controls: ["zoomControl"]
            });

            this.fullscreenControl = new ymaps.control.FullscreenControl();
            this.map.controls.add(this.fullscreenControl);

            this.map.behaviors.disable('scrollZoom');

            this.placemark = new ymaps.Placemark(this.coords, {
                hintContent: ''
            }, {
                preset: 'islands#icon',
                iconColor: '#ff3a3a'
            });
            this.map.geoObjects.add(this.placemark);

            //Когда модальное окно откроетя, карту нужно по размеру контейнера отмасштабировать
            $(document).on('opened.fndtn.reveal', '[data-reveal]', function () {
                obj.map.container.fitToViewport();
            });


        }

        enterFullscreen() {
            this.fullscreenControl.enterFullscreen();
        }
    }

    $(document).on("ym:ready", () => {
        $("#places-show-map")
            .add("#places-show-map-in-modal")
            .each(function () {
                let map = new Map({
                    elem: $(this)
                });
            });
    });

    //Галерея в модальном окне
    let gallery;

    $(document).on("opened.fndtn.reveal", "[data-reveal]", function () {
        if ($(this).is("#places-show-photo-report-modal") && !gallery) {
            //Инициализировать галерею можно только в том случаи, если она видна
            //специально отслеживается событие окончания открытия окна, чтобы галерея уже
            //точно была польностью видна
            //Инициализируем галерею только один раз
            gallery = new GalleryComponent({
                elem: $(this).find(".gallery")
            });

            //Прокрутим на выбранный слайд
            gallery.moveToSlide($(this).data("index"), true, 1);
        } else if ($(this).is("#places-show-photo-report-modal") && gallery) {
            //Галерея уже инициазирована. Просто прокрутим ее на нужное место
            //специально отслеживается событие окончания открытия окна, чтобы галерея уже точно была видна
            //Также необходимо "перенастроить" галерею (setup). Дело в том, что когда модальное окно закрывается
            //и потом пользователь меняет размер экрана, то в момент этого изменения размера плагин меняет размер
            //галереи, но окно не видно и размер галереи становится 0. Поэтому если не "перенастроить" ее
            //когда она теперь стала видна, то она может не отобразиться
            gallery.setup();
            gallery.moveToSlide($(this).data("index"), true, 1);
        }
    });

    //По клику на миниатюре на странице установим дата-атрибут активного слайда
    $("section.places.show .photo-report > .minis a").click(function () {
        let index = $(this).data("index");
        $("#places-show-photo-report-modal").data("index", index);
    });

    //Галерея отзывов
    class ReviewsGallery {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;
            this.rootElem = this.elem.find(".root");

            this.rootElem.on("jcarousel:reload jcarousel:create", function () {
                let carousel = $(this);
                let width = carousel.innerWidth();

                if (width > 700) {
                    width = width / 2;
                }

                carousel.jcarousel("items").css("width", `${width}px`);
            }).jcarousel({
                wrap: 'circular',
                transitions: true,
                animation: 800
            });

            this.elem.find(".control.left").jcarouselControl({
                target: "-=1"
            });

            this.elem.find(".control.right").jcarouselControl({
                target: "+=1"
            });
        }
    }

    let reviewsGallery = new ReviewsGallery({
        elem: $("section.main.places.show")
    });

    //Миниатюра ютуб
    class YouTubeThumb {
        constructor(options) {
            this.elem = options.elem;

            let iframeElem = this.elem.find(".reveal-modal iframe");
            let iframeSrc = iframeElem.attr("src");
            let videoId = iframeSrc.match(/embed\/(.+)/)[1];

            this.elem.css("background-image", `url('https://img.youtube.com/vi/${videoId}/0.jpg')`);
        }


    }

    //Если есть модальное окно с видео
    let videoElem = $("section.main.places.show .leftcol .video-report");
    if (videoElem.length) {
        new YouTubeThumb({
            elem: videoElem
        });
    }
});