//Мнения экспертов на главной
class ExpandComponent {
    constructor(options) {
        let obj = this;
        this.elem = options.elem;

        if(this.elem.height() > 200) {
            this.elem.addClass("__expand-component-collapsed");

            this.elem.click(function(event) {
                obj.showFullText();
            });
        }
    }

    showFullText() {
        this.elem.removeClass("__expand-component-collapsed");
    }
}

$(document).on("page:loaded", () => {
    // Пока что отключили данный функционал
    return;

    $(".expand-component")
        .checkComponentElem("expand-component-initialized")
        .each(function() {
        new ExpandComponent({
            elem: $(this)
        });
    });
});
