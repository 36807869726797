<template functional>
  <svg><use :xlink:href="props.href"></use></svg>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      required: true
    }
  },
}
</script>
