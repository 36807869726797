//Галерея
class WysiwygGallery {
    constructor(options) {
        this.elem = options.elem;
        this.elem.fotorama();
    }
}

//Ссылка-якорь
class WysiwygAnchor {
    constructor(options) {
        this.elem = options.elem;

        this.elem.click((event) => {
            event.preventDefault();
            this.processClick();
        });
    }

    processClick() {
        let targetElem = $(this.elem.attr('href'));

        //Если мы находимся внутри закрытого блока материла
        //то нам может потребоваться открыть блок сначала
        let ucItem = this.elem.closest('.ct-uc-item');
        if (ucItem.length) {
            this.elem.trigger('uc:expand_elem', [ucItem]);
        }

        setTimeout(() => {
            $(window).scrollTo(targetElem, 1000, {offset: -50});
        }, 50);
    }
}

//Раскрывающийся блок
class WysiwygExpand {
    constructor(options) {
        this.elem = options.elem;

        this.elem.click((event) => {
            let separatorELem = $(event.target).closest('.text_separator');
            if(separatorELem.length && this.elem[0].contains(event.target)) {
                this.openElem();
            }
        });
    }

    openElem() {
        this.elem.addClass('__opened');
    }
}

$(document).on("page:loaded", () => {
    $(".wysiwyg-component .cw_gallery")
        .checkComponentElem("cw_gallery-initialized")
        .each((index, elem) => {
            new WysiwygGallery({
                elem: $(elem)
            });
        });

    $(".wysiwyg-component a[href^='#']")
        .checkComponentElem("cw-anchor-initialized")
        .each((index, elem) => {
            new WysiwygAnchor({
                elem: $(elem)
            });
        });

    $(".wysiwyg-component .cw_expand")
        .checkComponentElem("cw_expand-initialized")
        .each((index, elem) => {
            new WysiwygExpand({
                elem: $(elem)
            });
        });
});