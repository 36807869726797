class CentralPlaceComponent {
    constructor(options) {
        this.elem = options.elem;
        this.mapElem = this.elem.find(".ct-map");

        if (this.mapElem.length) {
            $(document).on("ym:ready", () => {
                this.initMap();
            });

            //Если инициализируем компонент уже после того как отработал коллбек готовности яндекс карт
            if(window.ym_init_fired) {
                this.initMap();
            }
        }

        //Градиент рандомный установим
        let gradients = ["__light-green", "__green", "__dark-green",
            "__dark-violet", "__red", "__violet",
            "__blue", "__light-blue", "__dark-blue"];

        let randomGradient = gradients[this.getRandomInteger(0, 8)];
        this.elem.find(".ct-gradient.gradient-component").addClass(randomGradient);
    }

    initMap() {
        if(this.mapInited) {
            return;
        }

        this.coords = [this.mapElem.data("lat"), this.mapElem.data("long")];

        this.map = new ymaps.Map(this.mapElem[0], {
            center: this.coords,
            zoom: this.mapElem.data("zoom"),
            controls: ["zoomControl"]
        });

        this.fullscreenControl = new ymaps.control.FullscreenControl();
        this.map.controls.add(this.fullscreenControl);

        this.map.behaviors.disable('scrollZoom');

        this.placemark = new ymaps.Placemark(this.coords, {
            hintContent: ''
        }, {
            preset: 'islands#icon',
            iconColor: '#ff3a3a'
        });
        this.map.geoObjects.add(this.placemark);

        this.mapInited = true;
    }

    getRandomInteger(min, max) {
        var rand = min - 0.5 + Math.random() * (max - min + 1)
        rand = Math.round(rand);
        return rand;
    }
}

$(document).on("page:loaded", function () {
    $(".central-place-component")
        .checkComponentElem("central-place-component-initialized")
        .each(function () {
            new CentralPlaceComponent({
                elem: $(this)
            });
        });
});