$(document).on("ready", function() {
    if (!$("section.main.profile.index").length) {
        return;
    }

    //Карта
    class Map {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;
            this.coords = [this.elem.data("lat"), this.elem.data("long")]

            this.map = new ymaps.Map("profile-index-map", {
                center: this.coords,
                zoom: this.elem.data("zoom"),
                controls: ['fullscreenControl', 'zoomControl']
            });
            this.map.behaviors.disable('scrollZoom');

            this.placemark = new ymaps.Placemark(this.coords, {
                hintContent: ''
            }, {
                preset: 'islands#icon',
                iconColor: '#ff3a3a'
            });
            this.map.geoObjects.add(this.placemark);

        }
    }

    $(document).on("ym:ready", () => {
        let map = new Map({
            elem: $("#profile-index-map")
        });
    });
});