class InfinityLoadComponent {
    constructor(options) {
        this.elem = options.elem;

        this.elem.click((event) => {
           if($(event.target).closest('.ct-il-pagination a.next_page').length
               && this.elem[0].contains(event.target)) {
               event.preventDefault();
               this.processClick();
           }
        });
    }

    processClick() {
        let link = this.elem.find('.ct-il-pagination a.next_page');

        //Если нету у нас больше следующих страниц, то завершаем все
        if (!link.length) {
            return;
        }

        let url = link.attr('href');

        //Отправляем запрос на сервер за пачкой контента
        link.closest('.ct-il-pagination').addClass('__disabled');

        $.get(url, (data) => {
            //Прошлую пагинацию удаяем, она больше не нужна
            link.closest('.ct-il-pagination').remove();

            this.elem.append(data);
            $(document).trigger('page:loaded');
        });
    }
}

$(document).on('page:loaded', () => {
    $('.infinity-load-component')
        .checkComponentElem('infinity-load-component-initialized')
        .each((index, elem) => {
            new InfinityLoadComponent({
                elem: $(elem)
            });
        });
});