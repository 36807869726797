$(document).on("ready", function () {
    if (!$("section.main.people.show").length) {
        return;
    }

    // Скрываем поиск
    $(".main-search-form-container").hide();

    let gallery;

    $(document).on("opened.fndtn.reveal", "[data-reveal]", function () {
        if ($(this).is("#people-show-photo-report-modal") && !gallery) {
            //Инициализировать галерею можно только в том случаи, если она видна
            //специально отслеживается событие окончания открытия окна, чтобы галерея уже
            //точно была польностью видна
            //Инициализируем галерею только один раз
            gallery = new GalleryComponent({
                elem: $(this).find(".gallery")
            });

            //Прокрутим на выбранный слайд
            gallery.moveToSlide($(this).data("index"), true, 1);
        } else if ($(this).is("#people-show-photo-report-modal") && gallery) {
            //Галерея уже инициазирована. Просто прокрутим ее на нужное место
            //специально отслеживается событие окончания открытия окна, чтобы галерея уже точно была видна
            //Также необходимо "перенастроить" галерею (setup). Дело в том, что когда модальное окно закрывается
            //и потом пользователь меняет размер экрана, то в момент этого изменения размера плагин меняет размер
            //галереи, но окно не видно и размер галереи становится 0. Поэтому если не "перенастроить" ее
            //когда она теперь стала видна, то она может не отобразиться
            gallery.setup();
            gallery.moveToSlide($(this).data("index"), true, 1);
        }
    });

    //По клику на миниатюре на странице установим дата-атрибут активного слайда
    $("section.people.show .photo-report > .minis a").click(function () {
        let index = $(this).data("index");
        $("#people-show-photo-report-modal").data("index", index);
    });

    //Миниатюра ютуб
    class YouTubeThumb {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;

            let iframeElem = this.elem.find(".reveal-modal iframe");
            let iframeSrc = iframeElem.attr("src");
            let videoId = iframeSrc.match(/embed\/(.+)/)[1];

            this.elem.css("background-image", `url('https://img.youtube.com/vi/${videoId}/0.jpg')`);
        }


    }

    //Если есть модальное окно с видео
    let videoElem = $("section.main.people.show .video-report");
    if (videoElem.length) {
        new YouTubeThumb({
            elem: videoElem
        });
    }
});
