let gaInited = false

class GoogleAnalyticsComponent {
  constructor(options) {
    //Одновременно на странице может работать только один компонент
    if (gaInited) return
    gaInited = true

    this.elem = options.elem
    this.hittedUrls = []

    //Код аналитики изначально не выводится. Мы сами вставляем его
    this.init()

    //Это событие генерируем компонент автодоскрола - изменился УРЛ страницы
    $(document).on("aps:url_change", this.hit.bind(this))
  }

  init() {
    this.elem.append(this.elem.data('code'))
  }

  hit() {
    let url = location.href
    if (this.hittedUrls.indexOf(url) !== -1) {
      //Обновляем реферер
      this.referer = location.href
      return
    }

    //Если не инициализирован АПИ
    if (typeof ga === 'undefined') {
      return
    }

    ga('send', 'pageview', location.href)
    this.hittedUrls.push(url)
  }
}

$(document).on("page:loaded", () => {
  $(".google-analytics-component")
    .checkComponentElem()
    .each((index, elem) => {
      try {
        new GoogleAnalyticsComponent({
          elem: $(elem)
        })
      } catch (e) {
        console.error(e.message)
      }
    })
})