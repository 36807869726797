$(document).on("ready", function () {
    let pageElem = $("html.iframe-page");
    if (!pageElem.length) return;

    //return;

    class IframePage {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;
            this.iframeUrl = $.queryStringParse(location.search).url;

            this.iframeUrl = this.iframeUrl.match(/^https?:\/\/(.+)$/i)[1];

            this.refferer = document.referrer;

            //Подставим нужный УРЛ в iframe
            this.elem.find("iframe").attr("src", "//" + this.iframeUrl);

            //На не тач девайсах добавим класс not-touch
            //Делать нужно именно так, чтобы на ios сразу без всякого js
            //стояло правильное значение overflow, а на не тач через js
            //добавлением класса ставим другое значение overflow
            //вызвана такая особенность глюками ios
            if(!Modernizr.touch) {
                this.elem.find(".iframe-block").addClass("not-touch");
            }

            //Ссылка
            this.elem.find(".url").text(this.iframeUrl);

            //Заголовок страницы
            $(document).find("title").text(`Сайт ${this.iframeUrl}`);

            this.elem.click(function(event) {
               if($(event.target).closest(".close").length &&
               obj.elem[0].contains(event.target)) {
                   event.preventDefault();
                   obj.leaveLikesWebsite();
               }
            });

            this.elem.click(function(event) {
                if($(event.target).closest(".back").length &&
                    obj.elem[0].contains(event.target)) {
                    event.preventDefault();
                    obj.backToLikes();
                }
            });
        }

        leaveLikesWebsite() {
            window.location = "//" + this.iframeUrl;
        }

        backToLikes() {
            window.location = this.refferer;
        }
    }

    let iframePage = new IframePage({
       elem: pageElem
    });
});