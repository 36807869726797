//Фото-галерея
class GalleryComponent {
    constructor(options) {
        let obj = this;

        this.elem = options.elem;
        this.root = this.elem.find(".ct-root");

        this.swipe = this.root.Swipe({
            speed: 700,
            transitionEnd: function (index) {
                obj.moveToSlide(index);
            }
        }).data("Swipe");

        this.minisELem = this.elem.find(".ct-minis");
        this.minis = this.elem.find(".ct-minis a");

        this.minisELem
            //Когда карусель появилась или сдвинулась нужно показать видимые миниатюру
            .on("jcarousel:createend jcarousel:animateend jcarousel:reload", (event) => {
                if (event.type == "jcarousel:createend") {
                    //Отметим галерею инициализованной - небольшие изменения стилей
                    //произойдут. Отмечаем именно здесь, потому что инициализация карусели
                    //это последнее что происходит при инициализации галерери
                    //плагин Swipe инициализируется тут же - в момент вызова
                    this.elem.addClass("__initialized");

                    //Сразу для первого слайда изображени подгрузим
                    this.setInterchangeAttr(this.elem.find(".ct-main-img").eq(0));
                }

                this.loadMinisImg();
            })
            .jcarousel({
                transitions: true,
                animation: {
                    duration: 700
                }
            });

        //Клик по миниатюре
        this.elem.click(function (event) {
            let mini = $(event.target).closest(".ct-minis a");

            if (mini.length && $.contains(obj.elem[0], event.target)) {
                event.preventDefault();
                obj.moveToSlide(mini.data("index"), true, false);
            }
        });

        //Клик по контролу
        this.elem.click(function (event) {
            let control = $(event.target).closest(".ct-control");
            if (control.length
                && $.contains(obj.elem[0], event.target)) {

                if (control.hasClass("__left")) {
                    obj.movePrev();
                } else {
                    obj.moveNext();
                }
            }
        });

        //Клик по главному изображению
        this.elem.click(function (event) {
            if ($(event.target).closest(".ct-main-img").length &&
                $.contains(obj.elem[0], event.target)) {
                obj.moveNext();
            }
        });
    }

    //Метод ставит элементу изображение на бекграунд с помощью
    //foundation. Это нужно для загрузки изображений галереи не сразу всех скопом,
    //а по требованию, чтобы страница при загрузке не тормозила
    setInterchangeAttr(imageElem) {
        if (imageElem.attr("data-interchange")) {
            return;
        }

        imageElem
            .attr("data-interchange", imageElem.data("images"))
            .foundation("interchange", "reflow");
    }

    miniIsVisible(miniElem) {
        let minisContainerBox = this.minisELem[0].getBoundingClientRect();
        let miniElemBox = miniElem[0].getBoundingClientRect();

        if (minisContainerBox.right < miniElemBox.left ||
            minisContainerBox.left > miniElemBox.right) {
            return false;
        }

        return true;
    }

    loadMinisImg() {
        this.minis.find(".ct-mini-bg").each((index, elem) => {
            elem = $(elem);

            if (elem.attr("data-interchange") || !this.miniIsVisible(elem)) {
                return;
            }

            this.setInterchangeAttr(elem);
        });
    }

    moveToSlide(index, slideTo, loadMainImage = true) {
        this.minis.removeClass("__active");
        this.minis.eq(index).addClass("__active");

        this.minisELem.jcarousel("scrollIntoView", index, true);

        if (slideTo) {
            this.swipe.slide(index, 700);
        }

        if (loadMainImage) {
            //Поставим атрибут data-interchange текущему слайду
            this.setInterchangeAttr(this.elem.find(".ct-main-img").eq(index));
        }
    }

    movePrev() {
        this.swipe.prev();
    }

    moveNext() {
        this.swipe.next();
    }

    setup() {
        this.swipe.setup();
    }
}

$(document).on("page:loaded", () => {
    $(".gallery-component")
        .checkComponentElem("gallery-component-initialized")
        .each((index, elem) => {
            new GalleryComponent({
                elem: $(elem)
            });
        });
});

