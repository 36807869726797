class ModalComponent {
    constructor(options) {
        this.elem = options.elem;
        this.bodyElem = $('body');
        this.modalElem = this.bodyElem.find(this.elem.data('modal'));

        //Если это окно-видеогалерея
        let videoUrls = this.modalElem.data('video-urls');
        if(videoUrls) {
            this.videoUrls = videoUrls;
        }

        this.bodyElem.append(this.modalElem);

        if(this.elem.hasClass('__show-immediately')) {
            this.showModal();
        }

        this.elem.click((event) => {
            let link = $(event.target).closest('a');
            //Кликнули по ссылке внутри триггера но не по самому триггеру
            if(link.length && link != this.elem) {
                return;
            }

            event.preventDefault();
            this.showModal();
        });

        this.modalElem.click((event) => {
            if ($(event.target).closest('.ct-mc-close').length &&
                this.modalElem[0].contains(event.target)) {
                this.hideModal();
            }
        });

        //Следующее видео в галереи
        this.modalElem.click((event) => {
            if($(event.target).closest('.ct-mc-video-gallery-controls .prev').length &&
                this.modalElem[0].contains(event.target)) {
                this.prevVideo();
            }
        });

        //Прошлое видео в галереи
        this.modalElem.click((event) => {
            if($(event.target).closest('.ct-mc-video-gallery-controls .next').length &&
                this.modalElem[0].contains(event.target)) {
                this.nextVideo();
            }
        });
    }

    showModal() {
        let topOffset = pageYOffset + 70;

        this.modalElem.css('top', `${topOffset}px`);
        this.modalElem.fadeIn();

        //Отдельное видео
        if (this.elem.data('video-url')) {
            $.get({
                url: this.elem.data('video-url'),
                dataType: 'html'
            }).then((data) => {
                this.modalElem.find('.ct-mc-video').html(data);
            });
        }

        //Галерея видео
        if(this.videoUrls) {
            this.modalElem.find('.ct-mc-video-container').html(`<video controls src='${this.videoUrls[0].url}'>`);
            if(this.videoUrls[0].poster) {
                this.modalElem.find('.ct-mc-video-container video').attr('poster', this.videoUrls[0].poster);
            }
            this.modalElem.find('.ct-mc-video-gallery-controls svg.prev').addClass('disabled');
            this.currentVideoGalleryIndex = 0;
        }
    }

    hideModal() {
        this.modalElem.fadeOut(400, () => {
            this.modalElem.find('.ct-mc-video').html('');
            this.modalElem.find('.ct-mc-video-container').html('');
            this.modalElem.find('.ct-mc-video-gallery-controls svg').removeClass('__disabled');

        });
    }

    prevVideo() {
        let controlElem = this.modalElem.find('.ct-mc-video-gallery-controls .prev');
        let newCurrentIndex = this.currentVideoGalleryIndex - 1;

        if(newCurrentIndex < 0) {
            controlElem.addClass('__disabled');
            return;
        }

        //Будем переключать. Уберем с контрола Вперед выключенный класс
        this.modalElem.find('.ct-mc-video-gallery-controls .next').removeClass('__disabled');

        if(newCurrentIndex == 0) {
            controlElem.addClass('__disabled');
        } else {
            controlElem.removeClass('__disabled');
        }

        this.currentVideoGalleryIndex = newCurrentIndex;
        let videoElem = this.modalElem.find('.ct-mc-video-container video');
        videoElem.attr('src', this.videoUrls[this.currentVideoGalleryIndex].url);
        if(this.videoUrls[this.currentVideoGalleryIndex].poster) {
            videoElem.attr('poster', this.videoUrls[this.currentVideoGalleryIndex].poster);
        }
    }

    nextVideo() {
        let controlElem = this.modalElem.find('.ct-mc-video-gallery-controls .next');
        let newCurrentIndex = this.currentVideoGalleryIndex + 1;

        if(newCurrentIndex >= this.videoUrls.length) {
            controlElem.addClass('__disabled');
            return;
        }

        //Будем переключать видео, уберем с контрола Назад выкглюченный класс
        this.modalElem.find('.ct-mc-video-gallery-controls .prev').removeClass('__disabled');

        if(newCurrentIndex >= this.videoUrls.length - 1) {
            controlElem.addClass('__disabled');
        } else {
            controlElem.removeClass('__disabled');
        }

        this.currentVideoGalleryIndex = newCurrentIndex;
        let videoElem = this.modalElem.find('.ct-mc-video-container video');
        videoElem.attr('src', this.videoUrls[this.currentVideoGalleryIndex].url);
        if(this.videoUrls[this.currentVideoGalleryIndex].poster) {
            videoElem.attr('poster', this.videoUrls[this.currentVideoGalleryIndex].poster);
        }
    }
}

$(document).on('page:loaded', () => {
    $('.modal-trigger')
        .checkComponentElem('modal-trigger-inited')
        .each((index, elem) => {
            new ModalComponent({
                elem: $(elem)
            });
        });
});
