$(document).on("ready", () => {
    class SuperEvent {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;

            this.elem.click(function (event) {
                if (!$(event.target).closest("a").length &&
                    obj.elem[0].contains(event.target)) {
                    obj.misClickHelper($(event.target).closest("article"));
                }
            });
        }

        misClickHelper(itemElem) {
            let link = itemElem.find(".name a");
            if (!link.length) return;

            link[0].click();
        }
    }

    $("section.super-events article")
        .checkComponentElem("super-event-initialized")
        .each(function () {
            new SuperEvent({
                elem: $(this)
            });
        });
});

