//На странице должен быть только один экземпляр этого класса. Иначе будут глюки.
//Фактически, задача покмонента просто показывать/скрывать этот блок
//в определенное время в определенном месте
import ShareComponent from './_share'

class ShareOnSelectComponent {
    constructor(options) {
        let obj = this;
        this.elem = options.elem;
        let source = $("#handlebars-share-on-select-template").html();
        let template = Handlebars.compile(source);
        let html = $(template());
        this.boxElem = html.find(".share-on-select-component-box");
        this.mistypeFormElem = this.boxElem.find(".ct-mistype-form");
        this.thanxForMistypeElem = html.find(".share-on-select-component-mistype-thanx");
        $('body').append(this.boxElem.add(this.thanxForMistypeElem));

        //Поскольку у нас компонент шары был в Handlebars шаблоне, то нужно его инициалзировать вручную
        let shareElem = this.boxElem.find('.share-component');
        if(shareElem.length) {
            new ShareComponent({
                elem: shareElem
            });
        }

        this.elem.on("mouseup", function (event) {
            let selection = window.getSelection().toString();
            selection = $.trim(selection);

            if (selection) {
                obj.showBox(event.pageX, event.pageY);
            }
        });

        this.boxElem.click(function (event) {
            if ($(event.target).closest(".ct-typo").length &&
                obj.boxElem[0].contains(event.target)) {
                event.preventDefault();
                obj.sendMistypeForm();
            }
        });

        $(document).click(function (event) {
            if (obj.boxElem[0].contains(event.target)) {
                return;
            }

            let selection = window.getSelection().toString();
            selection = $.trim(selection);

            if (selection && obj.elem[0].contains(event.target)) {
                return;
            }

            obj.hideBox();
        });
    }

    showBox(pageX, pageY) {
        let left = pageX + 15;
        let top = pageY + 15;

        let documentWidth = document.documentElement.clientWidth;
        //Сначала покажем бокс, иначе не получится вычислить размеры скрытого блока
        this.boxElem.addClass("__active");
        let boxElemWidth = this.boxElem.width();

        //Чтобы не вылезал справа за край экрана
        if (pageX + boxElemWidth > documentWidth - 60) {
            left = documentWidth - boxElemWidth - 40;
        }

        this.boxElem.css({
            "top": `${top}px`,
            "left": `${left}px`
        });
    }

    hideBox() {
        this.boxElem.removeClass("__active");
    }

    sendMistypeForm() {
        let selection = window.getSelection().toString();
        this.mistypeFormElem.find(".ct-url").val(location.href);
        this.mistypeFormElem.find(".ct-text").val(selection);

        this.mistypeFormElem.ajaxSubmit(() => {
            this.thanxForMistypeElem.fadeIn();

            setTimeout(() => {
                this.thanxForMistypeElem.fadeOut()
            }, 5000);
        });
    }
}

$(document).on("page:loaded", () => {
    $(".share-on-select-component-elem")
        .checkComponentElem("share-on-select-component-elem-initialized")
        .each(function () {
            new ShareOnSelectComponent({
                elem: $(this)
            });
        });
});