$(document).on("ready", function () {
    let searchFormElem = $(".main-search-form");
    if (!searchFormElem.length) return;


    class MainSearchFormFolding {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;
            this.textField = this.elem.find("input.query");
            this.filterElems = this.elem.find(".filter");

            this.textField.focus(function () {
                obj.elem.addClass("active");
            });

            this.elem.click(function (event) {
                let trigger = $(event.target).closest(".trigger");
                if (trigger.length &&
                    obj.elem[0].contains(event.target)) {

                    obj.toggleDropdown(trigger);
                    obj.elem.addClass("active");
                }
            });

            //Клик мимо фильтра
            $(document).click(function (event) {
                //Если у нас в данный момент виден календарь, то фильтры скрывать не будем
                let datepicker = $(".ui-datepicker");
                if(datepicker.is(":visible")) return;

                if (!$(event.target).closest(".filter").length || !obj.elem[0].contains(event.target)) {
                    obj.filterElems.removeClass("active");
                }
            });
        }

        toggleDropdown(triggerElem) {
            let obj = this;
            let filterElem = triggerElem.closest(".filter")
            let filterElems = obj.filterElems;

            filterElems.not(filterElem).removeClass("active");

            if (filterElem.hasClass("active")) {
                filterElem.removeClass("active");
            } else {
                filterElem.addClass("active");
            }
        }
    }

    let mainSearchFormFolding = new MainSearchFormFolding({
        elem: searchFormElem
    });

    //Сборщик параметров поиска
    class SearchCollector {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;
            this.queryInputElem = this.elem.find("input.query");
            this.queryContainerElem = this.elem.find(".tags-and-input-container");

            //Шаблон для элемента внутри блока с инпутом
            this.selectedElemTemplate = $("#main-search-form-selected-elem-template").html();
            this.selectedElemTemplate = Handlebars.compile(this.selectedElemTemplate);

            //Календарь для дат
            this.elem.find("[data-search-option-date]").datepicker({
                minDate: "-12M",
                maxDate: "+12M",
                changeMonth: true,
                changeYear: true,
                dateFormat: "yy-mm-dd"
            });

            //Добавим стандартную опцию
            this.elem.click(function (event) {
                let optionElem = $(event.target).closest("[data-search-option]");
                if (optionElem.length &&
                    obj.elem[0].contains(event.target)) {
                    event.preventDefault();
                    if (optionElem.hasClass("active")) {
                        obj.removeOption(optionElem);
                    } else {
                        obj.addOption(optionElem);
                    }

                    obj.addRemoveNumber(optionElem.closest(".filter"));
                    obj.setQueryElemWidth();
                }
            });

            //Добавим опцию Рядом со мной
            this.elem.click(function (event) {
                let optionElem = $(event.target).closest("[data-search-option-geo]");
                if (optionElem.length &&
                    obj.elem[0].contains(event.target)) {
                    event.preventDefault();

                    if (optionElem.hasClass("active")) {
                        obj.removeGeoOption();
                        obj.addRemoveNumber(optionElem.closest(".filter"));
                    } else {
                        obj.addGeoOption();
                    }

                    obj.setQueryElemWidth();
                }
            });

            //Добавим опцию даты От До
            this.elem.change(function (event) {
                let selectElem = $(event.target).closest("[data-search-option-date]");
                if (selectElem.length &&
                    obj.elem[0].contains(event.target)) {
                    event.preventDefault();
                    obj.addDateOption(selectElem);

                    obj.addRemoveNumber(selectElem.closest(".filter"));
                    obj.setQueryElemWidth();
                }
            });

            //Удалим опцию
            this.elem.click(function (event) {
                let removeElem = $(event.target).closest(".tags-and-input-container .selected .remove");
                let selectedElem = removeElem.closest(".selected");
                if (removeElem.length &&
                    obj.elem[0].contains(event.target)) {
                    event.preventDefault();

                    //Отдельно обработаем удаление опции Рядом со мной
                    if (selectedElem.data("search-category") == "where" &&
                        selectedElem.data("search-key") == "geo") {
                        obj.removeGeoOption();
                    } else if (selectedElem.data("search-category") == "when" &&
                        (selectedElem.data("search-key") == "date_from" || selectedElem.data("search-key") == "date_to")) {
                        //Удалим опцию От До
                        obj.removeFromToOption(selectedElem);
                    }  else {
                        //Удалим обычную опцию
                        obj.removeOption(selectedElem, false);
                    }

                    //Кол-во выбранынх опций в контейнере
                    switch (selectedElem.data("search-category")) {
                        case "what":
                            obj.addRemoveNumber(obj.elem.find(".filter.what"));
                            break;
                        case "where":
                            obj.addRemoveNumber(obj.elem.find(".filter.where"));
                            break;
                        case "when":
                            obj.addRemoveNumber(obj.elem.find(".filter.when"));
                            break;
                        case "rates":
                            obj.addRemoveNumber(obj.elem.find(".filter.how-much"));
                            break;
                        case "price":
                            obj.addRemoveNumber(obj.elem.find(".filter.how-much"));
                            break;
                    }

                    obj.setQueryElemWidth();
                }
            });
        }

        setQueryElemWidth() {
            let containerWidth = this.queryContainerElem.width();
            let selectedElemsWidth = 0;
            this.queryContainerElem.find('.selected').each((index, elem) => {
                selectedElemsWidth += $(elem).outerWidth(true);
            });

            if((containerWidth - selectedElemsWidth) < containerWidth / 2) {
                this.queryInputElem.css('width', '100%');

            } else {
                this.queryInputElem.css('width', `${containerWidth - selectedElemsWidth - 6}px`);
            }
        }

        addRemoveNumber(filterElem) {
            let number = filterElem.find(".item.active").length;

            filterElem.find("select").each(function() {
               if($(this).val()) {
                   number++;
               }
            });

            let numberElem = filterElem.find(".trigger .number");
            numberElem.text(number);

            if(number) {
                numberElem.addClass("active");
            } else {
                numberElem.removeClass("active");
            }
        }

        addOption(optionElem) {
            let obj = this;

            let text;
            if (optionElem.data("search-text")) {
                text = optionElem.data("search-text");
            } else {
                text = optionElem.text();
            }

            let optionElemInQueryContainer = obj.getOptionElem(text);

            let searchCategory = optionElem.data("search-category");
            let searchKey = optionElem.data("search-key");

            let additionalClass;
            if (searchCategory == "where" || searchCategory == "rates" || searchCategory == "price") {
                additionalClass = "green";
            }

            optionElemInQueryContainer.addClass(additionalClass);

            //Ставим именно атрибуты, а не дата-параметры т.к. по ним потом будет происходить поиск
            optionElemInQueryContainer.attr("data-search-category", searchCategory);
            optionElemInQueryContainer.attr("data-search-key", searchKey);
            optionElemInQueryContainer.attr("data-search-value", optionElem.data("search-value"));

            //Вспомогательная функция для взаимоисключений
            function exceptionHelper(params) {
                params.forEach((param) => {
                    let findingCategory = param.category;
                    let findingKey = param.key;

                    if (searchCategory == findingCategory && searchKey == findingKey) {
                        obj.queryContainerElem.find(".selected")
                            .filter(`[data-search-category='${findingCategory}']`)
                            .filter(`[data-search-key='${findingKey}']`)
                            .remove();

                        optionElem.siblings(`.item[data-search-key='${findingKey}']`)
                            .removeClass("active");
                    }
                });
            }

            exceptionHelper([
                {category: "what", key: "module"},
                {category: "what", key: "event_category_id"},
                {category: "what", key: "place_category_id"},
                {category: "where", key: "city_area_id"},
                {category: "when", key: "pattern"},
                {category: "price", key: "max"},
                {category: "rates", key: "no"}
            ]);

            //Если выбрали район, то сбросим Рядом со мной
            if (searchCategory == "where" && searchKey == "city_area_id") {
                obj.removeGeoOption();
            }

            //Если выбрали категорию даты (сегодня, завтра и т.д.)
            //то сбросим диапазон дат
            if (searchCategory == "when" && searchKey == "pattern") {
                obj.queryContainerElem.find(".selected")
                    .filter("[data-search-category='when']")
                    .filter("[data-search-key='date_from']")
                    .remove();

                obj.queryContainerElem.find(".selected")
                    .filter("[data-search-category='when']")
                    .filter("[data-search-key='date_to']")
                    .remove();

                obj.elem
                    .find("[data-search-option-date-from]")
                    .add("[data-search-option-date-to]")
                    .val("");
            }

            //Если вырали Бесплатно, то уберем цены
            if (searchCategory == "price" && searchKey == "free") {
                obj.queryContainerElem.find(".selected")
                    .filter(`[data-search-category='price']`)
                    .filter(`[data-search-key='max']`)
                    .remove();

                optionElem.siblings(`.item[data-search-key='max']`)
                    .removeClass("active");
            }

            //Если выбрали Цена до, то снимем Бесплатно
            if (searchCategory == "price" && searchKey == "max") {
                obj.queryContainerElem.find(".selected")
                    .filter(`[data-search-category='price']`)
                    .filter(`[data-search-key='free']`)
                    .remove();

                optionElem.siblings(`.item[data-search-key='free']`)
                    .removeClass("active");
            }

            obj.queryInputElem.before(optionElemInQueryContainer);

            optionElem.addClass("active");
        }

        addGeoOption() {
            if (!Modernizr.geolocation) return;

            let obj = this;
            let optionELem = obj.elem.find(".item.near-me");

            if (optionELem.hasClass("disabled")) return;

            optionELem.addClass("disabled");

            navigator.geolocation.getCurrentPosition(function (position) {
                let optionElemInQueryContainer = obj.getOptionElem("Рядом со мной").addClass("green");
                optionElemInQueryContainer
                    //Тут добавляем именно атрибуты, т.к. они потом понадобятся для поиска элемента через  DOM
                    .attr("data-search-category", "where")
                    .attr("data-search-key", "geo")
                    .data({
                        "search-value": {
                            "latitude": position.coords.latitude,
                            "longitude": position.coords.longitude
                        }
                    });

                obj.queryInputElem.before(optionElemInQueryContainer);

                optionELem.addClass("active").removeClass("disabled");

                //Удалим опции с районами
                obj.queryContainerElem.find(".selected")
                    .filter(`[data-search-category='where']`)
                    .filter(`[data-search-key='city_area_id']`)
                    .remove();
                obj.elem.find("[data-search-option]")
                    .filter(`[data-search-category='where']`)
                    .filter(`[data-search-key='city_area_id']`)
                    .removeClass("active");

                obj.addRemoveNumber(optionELem.closest(".filter"));
                obj.setQueryElemWidth();
            });
        }

        addDateOption(selectElem) {
            let obj = this;
            let text, search_key;
            if (selectElem.is("[data-search-option-date-from]")) {
                text = "От: ";
                search_key = "date_from";
            } else {
                text = "До: ";
                search_key = "date_to";
            }
            text += selectElem.val();

            let optionElemInQueryContainer = obj.getOptionElem(text);
            optionElemInQueryContainer
                .attr("data-search-category", "when")
                .attr("data-search-key", search_key)
                .data({"search-value": selectElem.val()});

            //Удалим из текстового поля все элементы Когда? базовые
            obj.queryContainerElem.find(".selected")
                .filter("[data-search-category='when']")
                .filter("[data-search-key='pattern']")
                .remove();

            //Удалим прошлый элемент От или До
            obj.queryContainerElem.find(".selected")
                .filter("[data-search-category='when']")
                .filter(`[data-search-key="${search_key}"]`)
                .remove();

            obj.elem.find(".filter.when .item").removeClass("active");

            //Если выбрали дату, а не сняли выбор, то добавим элемент
            if (selectElem.val()) {
                obj.queryInputElem.before(optionElemInQueryContainer);
            }

            //Если выбрали От, то сделаем До активным
            if (selectElem.is("[data-search-option-date-from]") && selectElem.val()) {
                obj.elem.find("[data-search-option-date-to]").removeAttr("disabled");
            }

            //Если сняли выбор с От, то сделаем До неактивным, сбросим его и удалим
            //поисковой строки дату До
            if (selectElem.is("[data-search-option-date-from]") && !selectElem.val()) {
                obj.elem.find("[data-search-option-date-to]").val("").attr("disabled", "disabled");

                obj.queryContainerElem.find(".selected")
                    .filter("[data-search-category='when']")
                    .filter("[data-search-key='date_to']")
                    .remove();
            }
        }

        //Удалять можно либо передавая элемент из блока фильтров, либо
        //элемент из блока где текстовое поле
        removeOption(elem, byFilterElem = true) {
            let obj = this;
            let filterElem, fieldBlockElem;
            if (byFilterElem) {
                filterElem = elem;
                fieldBlockElem = this.elem
                    .find(".tags-and-input-container .selected")
                    .filter(`[data-search-category="${filterElem.data("search-category")}"]`)
                    .filter(`[data-search-key="${filterElem.data("search-key")}"]`)
                    .filter(`[data-search-value="${filterElem.data("search-value")}"]`);

            } else {
                fieldBlockElem = elem;
                filterElem = this.elem
                    .find(".filter .item")
                    .filter(`[data-search-category="${fieldBlockElem.data("search-category")}"]`)
                    .filter(`[data-search-key="${fieldBlockElem.data("search-key")}"]`)
                    .filter(`[data-search-value="${fieldBlockElem.data("search-value")}"]`);
            }

            fieldBlockElem.remove();
            filterElem.removeClass("active");
        }

        removeGeoOption() {
            let obj = this;

            obj.elem.find(".tags-and-input-container .selected")
                .filter("[data-search-category='where']")
                .filter("[data-search-key='geo']")
                .remove();

            obj.elem.find(".item.near-me").removeClass("active");
        }

        removeFromToOption(option) {
            let obj = this;

            if (option.data("search-key") == "date_from") {
                obj.queryContainerElem.find(".selected")
                    .filter("[data-search-category='when']")
                    .filter("[data-search-key='date_from']")
                    .remove();

                obj.elem.find("[data-search-option-date-from]").val("");
            } else {
                obj.queryContainerElem.find(".selected")
                    .filter("[data-search-category='when']")
                    .filter("[data-search-key='date_to']")
                    .remove();

                obj.elem.find("[data-search-option-date-to]").val("");
            }
        }

        getOptionElem(text) {
            return $(this.selectedElemTemplate({text: text}));
        }
    }

    let searchCollector = new SearchCollector({
        elem: searchFormElem
    });

    //Формировщик запроса
    class SearchCompiler {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;
            this.formElem = this.elem;
            this.queryContainerElem = this.formElem.find(".tags-and-input-container");

            //Отправка запроса - сабмит формы
            this.formElem.submit(function (event) {
                event.preventDefault();

                let params = obj.compileParams();
                console.log(params);

                params = JSON.stringify(params);
                console.log(params);

                params = $.queryStringStringify({
                    search_params: params
                });
                console.log(params);

                let url = obj.elem.data("search-url") + "?" + params;
                console.log(url);

                //Плохо принимаются параметры на странице поиска - ошибки
                window.location.href = url;
            });
        }

        compileParams() {
            let obj = this;

            let compiledParams = {
                common: {
                    search_query: obj.formElem.find("input.query").val(),
                    recommend: false,
                    for_kids: false
                },
                extra: []
            };

            //Сформируем блоки экстра-параметров
            //Что (все кроме тегов)
            obj.queryContainerElem.find(".selected")
                .filter("[data-search-category='what']")
                .not("[data-search-key='tag_id']")
                .each(function () {
                    let paramsElem = {
                        category: "what",
                        key: $(this).data("search-key"),
                        value: $(this).data("search-value")
                    };

                    if (paramsElem.key == "place_id") {
                        paramsElem.description = $(this).data("search-description");
                    }

                    compiledParams.extra.push(paramsElem);
                });

            //Где
            obj.queryContainerElem.find(".selected")
                .filter("[data-search-category='where']")
                .each(function () {
                    compiledParams.extra.push({
                        category: "where",
                        key: $(this).data("search-key"),
                        value: $(this).data("search-value")
                    });
                });

            //Когда - обычные элементы
            obj.queryContainerElem.find(".selected")
                .filter("[data-search-category='when']")
                .filter("[data-search-key='pattern']")
                .each(function () {
                    compiledParams.extra.push({
                        category: "when",
                        key: $(this).data("search-key"),
                        value: $(this).data("search-value")
                    });
                });

            //Когда От
            let dateFrom = obj.queryContainerElem.find(".selected")
                .filter("[data-search-category='when']")
                .filter("[data-search-key='date_from']");

            //Когда До
            let dateTo = obj.queryContainerElem.find(".selected")
                .filter("[data-search-category='when']")
                .filter("[data-search-key='date_to']");

            if (dateFrom.length && dateTo.length) {
                compiledParams.extra.push({
                    category: "when",
                    key: "range",
                    value: {
                        from: dateFrom.data("search-value"),
                        to: dateTo.data("search-value")
                    }
                });
            } else if (dateFrom.length) {
                compiledParams.extra.push({
                    category: "when",
                    key: "date",
                    value: dateFrom.data("search-value")
                });
            } else if (dateTo.length) {
                compiledParams.extra.push({
                    category: "when",
                    key: "date",
                    value: dateTo.data("search-value")
                });
            }

            //Рейтинг
            obj.queryContainerElem.find(".selected")
                .filter("[data-search-category='rates']")
                .each(function () {
                    compiledParams.extra.push({
                        category: "rates",
                        key: null,
                        value: $(this).data("search-value")
                    });
                });

            //Теги
            obj.queryContainerElem.find(".selected")
                .filter("[data-search-category='what']")
                .filter("[data-search-key='tag_id']")
                .each(function () {
                    compiledParams.extra.push({
                        category: "what",
                        key: $(this).data("search-key"),
                        value: $(this).data("search-value")
                    });
                });

            //Цена
            obj.queryContainerElem.find(".selected")
                .filter("[data-search-category='price']")
                .each(function () {
                    compiledParams.extra.push({
                        category: "price",
                        key: $(this).data("search-key"),
                        value: $(this).data("search-value")
                    });
                });

            //Готово. Возвращаем
            return compiledParams;
        }
    }

    let searchCompiler = new SearchCompiler({
        elem: searchFormElem
    });

    //Автокомплит
    class SearchAutocomplete {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;
            this.inputElem = this.elem.find("input.query");
            this.autocompleteElem = this.elem.find(".autocomplete");
            this.cityId = this.elem.data("search-city-id");

            //Шаблон для элемента внутри блока с инпутом
            this.selectedElemTemplate = $("#main-search-form-selected-elem-template").html();
            this.selectedElemTemplate = Handlebars.compile(this.selectedElemTemplate);

            this.inputElem.click(function () {
                obj.showDropdown();
            });

            $(document).click(function (event) {
                if ($(event.target).closest("input.query").length) return;
                if ($(event.target).closest(".autocomplete").length) return;

                obj.hideDropdown();
            });

            this.inputElem.on("input", function () {
                if (obj.inputElem.val().length < 3) {
                    obj.hideDropdown();
                    return;
                }
                obj.getResults();
            });

            this.elem.click(function (event) {
                let chosenElem = $(event.target).closest(".autocomplete .elem");
                if (chosenElem.length &&
                    obj.elem[0].contains(event.target)) {
                    obj.chooseElem(chosenElem);
                }
            });
        }

        chooseElem(chosenElem) {
            //При выборе любого знаения кроме конкретного заведения
            //синтезируем клик по соответствующему элементу на странице
            //пусть всю обработку произведет сборщик параметров поиска
            if ((chosenElem.data("search-category") == "what" && chosenElem.data("search-key") == "place_id") ||
                (chosenElem.data("search-category") == "what" && chosenElem.data("search-key") == "tag_id")) {
                let elem = $(this.selectedElemTemplate({text: chosenElem.text()}));
                elem.attr({
                    "data-search-category": chosenElem.data("search-category"),
                    "data-search-key": chosenElem.data("search-key"),
                    "data-search-value": chosenElem.data("search-value"),
                    "data-search-description": chosenElem.text()
                });
                this.inputElem.before(elem);
            } else {
                let targetElem = this.elem.find("[data-search-option]")
                    .filter(`[data-search-category='${chosenElem.data("search-category")}']`)
                    .filter(`[data-search-key='${chosenElem.data("search-key")}']`)
                    .filter(`[data-search-value='${chosenElem.data("search-value")}']`);

                targetElem.trigger("click");
            }

            this.hideDropdown();
            this.inputElem.val("");
        }

        showDropdown() {
            this.autocompleteElem.addClass("active");
        }

        hideDropdown() {
            this.autocompleteElem.removeClass("active");
            this.autocompleteElem.html("");
        }

        getResults() {
            let obj = this;
            let url = obj.elem.data("search-autocomplete-url");
            let cityId = obj.elem.data("search-city-id");
            let searchQuery = obj.inputElem.val();

            $.getJSON(url, {city_id: cityId, search_query: searchQuery}, function (data) {
                obj.autocompleteElem.html("");
                obj.showDropdown();

                data.forEach(function (item) {
                    let elem = $(`<div class="elem">${item.description}</div>`);

                    let search_category;
                    if (item.type == "what" || item.type == "tags") {
                        search_category = "what";
                    } else if (item.type == "when") {
                        search_category = "when";
                    }

                    let search_key = item.key;
                    if (item.type == "tags") {
                        search_key = "tag_id";
                    }

                    let search_value = item.value;

                    elem.attr({
                        "data-search-category": search_category,
                        "data-search-key": search_key,
                        "data-search-value": search_value
                    });

                    obj.autocompleteElem.append(elem);
                });
            });
        }
    }

    let searchAutocomplete = new SearchAutocomplete({
        elem: searchFormElem
    });
});