//Компонет селект-табы
class SelectTabsComponent {
    constructor(options) {
        let obj = this;
        this.elem = options.elem;
        this.items = this.elem.find(".ct-item").not(".__all");

        //Расставим классы табам. Изначально они все имеют класс __abs
        this.items.each(function () {
            let index = obj.items.index(this);
            if (index == 0) {
                $(this).removeClass("__abs").addClass("__active");
            } else {
                $(this).addClass(`__${index}`);
            }
        });

        this.elem.click(function (event) {
            if ($(event.target).closest(".ct-item.__active").length &&
                obj.elem[0].contains(event.target)) {
                obj.showHideDropdown();
            }
        });

        this.elem.click(function (event) {
            let abs = $(event.target).closest(".ct-item.__abs");
            if (abs.length &&
                obj.elem[0].contains(event.target)) {
                obj.chooseItem(abs);
            }
        });

        $(document).click(function (event) {
            if (!obj.elem[0].contains(event.target)) {
                obj.hideDropdown();
            }
        });
    }

    showHideDropdown() {
        this.elem.toggleClass("__opened")
    }

    hideDropdown() {
        this.elem.removeClass("__opened");
    }

    chooseItem(abs) {
        let active = this.elem.find(".ct-item.__active");
        let numberClass;

        if (abs.hasClass("__1")) {
            numberClass = "__1";
        } else if (abs.hasClass("__2")) {
            numberClass = "__2";
        } else if (abs.hasClass("__3")) {
            numberClass = "__3";
        } else if (abs.hasClass("__4")) {
            numberClass = "__4";
        }

        active.removeClass("__active").addClass(`__abs ${numberClass}`);
        abs.removeClass(`__abs ${numberClass}`).addClass("__active");

        this.hideDropdown();
    }
}

$(document).on("page:loaded", () => {
    $(".select-tabs-component")
        .checkComponentElem("select-tabs-component-initialized")
        .each(function () {
            new SelectTabsComponent({
                elem: $(this)
            });
        });
});

