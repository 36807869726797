class MoviesTileComponent {
    constructor(options) {
        let obj = this;
        this.elem = options.elem;

        this.elem.click(function (event) {
            if (!$(event.target).closest("a").length && !$(event.target).closest(".trailer").length &&
                obj.elem[0].contains(event.target)) {
                obj.misClickHelper($(event.target).closest("article"));
            }
        });
    }

    misClickHelper(itemElem) {
        let link = itemElem.find(".name a");
        if (!link.length) return;

        link[0].click();
    }
}

$(document).on("page:loaded", () => {
    $(".movies-tile-component")
        .checkComponentElem("movies-tile-component-initialized")
        .each(function () {
            new MoviesTileComponent({
                elem: $(this)
            });
        });
});

