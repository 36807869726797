// Баннер прилипает к верху страницы при скролинге
// Скролится только в пределах блока .container в котором находится
class StickyBannerComponent {
    constructor(options) {
        this.elem = options.elem;
        this.banner = this.elem.find(".ct-banner");

        // min-height для контейнера банера устанавливается для того, чтобы при выпадении баннера из потока документа(position) страница не прыгала
        // settimeout для того, чтобы картинка успела загрузиться
        setTimeout(() => {
            this.elem.css({
                "min-height": this.banner.outerHeight(true)
            });
        }, 2000);

        $(window).bind("scroll resize", () => {
            this.toggleSticky();
        });
    }

    toggleSticky() {
        let bottomOffset = this.elem.closest(".container").offset().top + this.elem.closest(".container").outerHeight();

        //Есть один особый случай, когда банер находится внутри блока .ct-uc-item:not(.__opened) (см. компонент urlControl)
        //в этом случаи у нас низ контейнера может торчать из низа блока доскрола и тогда нужно использовать нижнюю позицию
        //не для контейнера, а для блока доскрола, если она меньше чем для контейнера
        let ucItem = this.elem.closest('.ct-uc-item:not(.__opened)')
        if(ucItem.length) {
            let ucItemBottomOffset = ucItem.offset().top + ucItem.outerHeight();

            if(bottomOffset > ucItemBottomOffset) {
                bottomOffset = ucItemBottomOffset;
            }
            //console.log(bottomOffset, ucItemBottomOffset);
        }

        if (pageYOffset > this.elem.offset().top && pageYOffset + this.banner.outerHeight(true) < bottomOffset) {
            this.banner.css({
                position: "fixed",
                top: 0
            });
        }
        else if (pageYOffset + this.banner.outerHeight(true) >= bottomOffset) {
            this.banner.css({
                position: "absolute",
                top: bottomOffset - this.elem.offset().top - this.banner.outerHeight(true)
            });
        }
        else {
            this.banner.css({
                position: "initial"
            });
        }
    }
}

$(document).on("page:loaded", () => {
    $(".sticky-banner-component")
        .checkComponentElem("sticky-banner-component-initialized")
        .each((index, elem) => {
            new StickyBannerComponent({
                elem: $(elem)
            });
        });
});