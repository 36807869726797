$(document).on("ready", function () {

  if(!$(".landing-page").length) {
    return;
  }

  class PreviewArticlesGallery {
      constructor(options) {
          let obj = this;
          this.elem = options.elem;

          $(this.elem).cycle({
              slides: "img",
              timeout: 5000,
              pauseOnHover: true,
              prev: ".prev",
              next: ".next",
              paused: false
          });
      }
  }

  $(".preview-articles .gallery .gallery-list").each(function() {
     let gallery = new PreviewArticlesGallery({
         elem: $(this)
     });
  });

  $(".intro .text .scroll-down").click(function () {
    $('html, body').animate({
        scrollTop: $(".preview-films").offset().top
    }, 800);
  });

  var $animation_elements = $('.preview-films .bg-rect');
  var $window = $(window);

  function inViewTrigger() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function() {
      var $element = $(this);
      var element_height = $element.outerHeight();
      var element_top_position = $element.offset().top;
      var element_bottom_position = (element_top_position + element_height);

      if ((element_bottom_position >= window_top_position) &&
        (element_top_position <= window_bottom_position)) {
        $element.fadeIn(800);
      } else {
        $element.fadeOut(800);
      }
    });
  }

  $window.on('scroll resize', inViewTrigger);

  function flyMacbook() {
    $('.macbook-fly').animate({'top': "+=25%"}, {
      duration: 2000,
      complete: function() {
          $('.macbook-fly').animate({top: "-=25%"}, {
              duration: 2000,
              complete: flyMacbook});
    }});
  }

  flyMacbook();

});
