class RateComponent {
    constructor(options) {
        let obj = this;
        this.elem = options.elem;

        this.hiddenFieldElem = this.elem.find(".ct-value");

        //Выбрал ли пользователь значение (кликнул ли на звезду)
        this.userChosed = false;

        //Отправил ли пользователь форму
        this.userSendedForm = false;

        this.starsElem = this.elem.find(".ct-stars");

        if (this.starsElem.hasClass("__1")) {
            this.initialRating = 1;
        } else if (this.starsElem.hasClass("__2")) {
            this.initialRating = 2;
        } else if (this.starsElem.hasClass("__3")) {
            this.initialRating = 3;
        } else if (this.starsElem.hasClass("__4")) {
            this.initialRating = 4;
        } else if (this.starsElem.hasClass("__5")) {
            this.initialRating = 5;
        }

        this.stars = [];

        this.stars.push(this.elem.find(".ct-star").eq(0));
        this.stars.push(this.elem.find(".ct-star").eq(1));
        this.stars.push(this.elem.find(".ct-star").eq(2));
        this.stars.push(this.elem.find(".ct-star").eq(3));
        this.stars.push(this.elem.find(".ct-star").eq(4));

        this.stars[0].hover(function () {
            obj.highlightStar(0);
        });

        this.stars[1].hover(function () {
            obj.highlightStar(1);
        });

        this.stars[2].hover(function () {
            obj.highlightStar(2);
        });

        this.stars[3].hover(function () {
            obj.highlightStar(3);
        });

        this.stars[4].hover(function () {
            obj.highlightStar(4);
        });

        this.starsElem.mouseleave(function () {
            obj.highlightStar(obj.initialRating - 1);
        });

        //Клик по звезде
        this.elem.click(function (event) {
            let star = $(event.target).closest(".ct-star");
            if (star.length && obj.elem[0].contains(star[0])) {

                obj.rate(star);
            }
        });

        //Отправка формы
        this.elem.ajaxForm({
            beforeSubmit: function () {
                obj.beforeSubmit();
            },
            success: function () {
                obj.processResponse();
            }
        });
    }

    highlightStar(number, force) {
        let obj = this;

        //Если пользователь выбрал рейтинг (кликнул на звезду), то ничего не надо делать
        //но есть параметр force, который если true, то все равно подсветим звезду
        if (obj.userChosed && !force) {
            return;
        }

        obj.starsElem.removeClass("__1 __2 __3 __4 __5").addClass(`__${number + 1}`);
    }

    rate(starElem) {
        let obj = this;

        //Если пользователь уже отправил форму, то ничего делать не будем
        if (obj.userSendedForm) {
            return;
        }

        let rating;
        if (starElem.is(obj.stars[0])) {
            rating = 1;
        } else if (starElem.is(obj.stars[1])) {
            rating = 2;
        } else if (starElem.is(obj.stars[2])) {
            rating = 3;
        } else if (starElem.is(obj.stars[3])) {
            rating = 4
        } else if (starElem.is(obj.stars[4])) {
            rating = 5;
        }

        obj.hiddenFieldElem.val(rating);
        obj.highlightStar(rating - 1, true);
        obj.elem.find("button[type=submit]").show();
        obj.elem.find(".ct-num").hide();
        obj.userChosed = true;
    }

    beforeSubmit() {
        let obj = this;
        obj.elem.find("button[type=submit]").hide();
        obj.elem.find(".ct-num").show();
        obj.userSendedForm = true;
    }

    processResponse() {
        let obj = this;
        let numElem = obj.elem.find(".ct-num span");
        numElem.html(+numElem.text() + 1);
        obj.elem.removeClass("__active");
    }
}


$(document).on("page:loaded", () => {
    $(".rate-component.__active")
        .checkComponentElem("rate-component-initialized")
        .each(function () {
            new RateComponent({
                elem: $(this)
            });
        });
});