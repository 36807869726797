let ymInited = false

class MetrikaComponent {
  constructor(options) {
    //Одновременно на странице может работать только один компонент
    if (ymInited) return
    ymInited = true

    this.elem = options.elem
    this.hittedUrls = []
    this.scrollsCounter = 0

    let script = document.createElement('script')
    script.src = "https://mc.yandex.ru/metrika/watch.js"
    document.body.appendChild(script)

    script.onload = this.processReady.bind(this)

    //Это событие генерируем компонент автодоскрола - изменился УРЛ страницы
    $(document).on("aps:url_change", this.hit.bind(this))
  }

  processReady() {
    //При инициализации счетчика уже будет засчитан просмотр текущей страницы
    //поэтому дополнительно метод hit вызывать не надо
    this.counter = new Ya.Metrika(this.elem.data("params"))

    //в this.referer сохраняем текущий УРЛ, который понадобится,
    //когда будем вызывать метод hit
    this.referer = location.href
  }

  hit() {
    let url = location.href
    if (this.hittedUrls.indexOf(url) !== -1) {
      //Обновляем реферер
      this.referer = location.href

      return
    }

    this.counter.hit(url, {referer: this.referer})
    this.hittedUrls.push(url)

    //Также фиксируем достижение цели при скроле
    this.counter.reachGoal("scroll")
    //Увеличим кол-во вызовов reachGoal('scroll')
    this.scrollsCounter++
    //Если это первое срабатывание, то также зафиксируем еще одну цель
    if (this.scrollsCounter === 1) {
      this.counter.reachGoal("1st-scroll")
    }

    //Обновляем реферер
    this.referer = location.href
  }
}

$(document).on("page:loaded", () => {
  $(".yandex-metrika-component")
    .checkComponentElem('metrika-inited')
    .each((index, elem) => {
      try {
        new MetrikaComponent({
          elem: $(elem)
        })
      } catch (e) {
        console.error(e.message)
      }
    })
})