class ItemOpinionsPage {
    constructor(options) {
        this.elem = options.elem;
        this.opinionFormElem = this.elem.find('form.leave-opinion');
        this.textareaTooltipFired = false;
        this.htmlElem = $('html');

        //Выбор рейтинга
        this.elem.on('change', (event) => {
            let radioElem = $(event.target).closest('.leave-opinion .rating input[type=radio]');
            if (radioElem.length && this.elem[0].contains(event.target)) {
                this.processRatingChange(radioElem);
            }
        });

        //Выбор файла
        this.elem.on('change', (event) => {
            let fileElem = $(event.target).closest('.leave-opinion .files input[type=file]');
            if (fileElem.length && this.elem[0].contains(event.target)) {
                this.processFileChange(fileElem);
            }
        });

        //Закрываем подсказку
        this.elem.click((event) => {
            if ($(event.target).closest('.leave-opinion .tooltip small').length
                && this.elem[0].contains(event.target)) {
               this.elem.find('.leave-opinion .tooltip').fadeOut();
            }
        });

        //Событие click срабатывает при клике на кнопку или при сабмите
        //После того как рекапча произведет проверку (первая отправка формы), при дальнейших кликах на кнопку
        //не будет вызываться событие submit. Поэтому нам приходится эго вызывать самим по клику
        this.opinionFormElem.find('.submit button').click(() => {
            this.opinionFormElem.trigger('submit');
        });

        this.opinionFormElem.on('submit', (event) => {
            event.preventDefault();

            //Пока рекапча не сработала, не даем отправлять форму
            if(!this.opinionFormElem.data('recaptcha-fired')) {
                return;
            }

            //Отправка формы
            this.opinionFormElem.ajaxSubmit({
                beforeSubmit: () => {
                    if (!this.elem.find('.rating').hasClass('__disabled') &&
                        !this.elem.find('.rating input[type=radio]:checked').length) {
                        alert('Пожалуйста, поставьте рейтинг');
                        return false;
                    }

                    if (!this.textareaTooltipFired && !this.elem.find('.leave-opinion textarea').val()) {
                        this.elem.find('.leave-opinion .textarea-container .tooltip').fadeIn();
                        this.textareaTooltipFired = true;
                        return false;
                    }

                    this.opinionFormElem.find('.submit button').attr('disabled', 'disabled');

                },
                success: () => {
                    //TODO анимация работает не гладко
                    this.opinionFormElem.slideUp(600);
                    setTimeout(() => {
                        this.elem.find('.thanx').fadeIn('slow');
                    }, 450)
                },
                error: (xhr) => {
                    let swearErrorText;
                    try {
                        swearErrorText = JSON.parse(xhr.responseText).errors.text;
                    } catch (e) {

                    }

                    let alertText = swearErrorText || 'Извините, произошла ошибка, попробуйте позже'
                    alert(alertText);
                    this.opinionFormElem.find('.submit button').removeAttr('disabled');

                }
            });
        });

        //Когда загрузится АПИ рекапчи, инициализируем ее
        if(this.htmlElem.data('recaptcha-loaded')) {
            this.addRecaptcha();
        }

        this.htmlElem.on('recaptcha-loaded', this.addRecaptcha.bind(this));
    }

    addRecaptcha() {
        grecaptcha.render(this.opinionFormElem.find('.submit button')[0], {
            sitekey: this.htmlElem.data('sitekey'),
            badge: 'inline',
            callback: () => {
                this.opinionFormElem
                    .data('recaptcha-fired', true)
                    .trigger('submit');
            }
        });
    }

    processFileChange(fileElem) {
        fileElem
            .siblings('small')
            .find('b')
            .text(fileElem[0].files.length);
    }

    processRatingChange(radioElem) {
        radioElem
            .closest('.rating')
            .removeClass('__1 __2 __3 __4 __5')
            .addClass(`__${radioElem.val()}`);
    }
}

$(document).on('page:loaded', () => {
    $('section.main.item-opinions')
        .checkComponentElem('item-opinions-page-initialized')
        .each((index, elem) => {
            new ItemOpinionsPage({
                elem: $(elem)
            });
        });
});