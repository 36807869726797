//Наш собственный небольшой jQuery плагин для компонентов
//Есть небольшое ограничение. На один и тот же блок 2 компонента
//одновременно нельзя навесить. В таких случаях когда нужно 2 или более
//на один блок навестить просто нужно создать еще один оберточный или более блок
//и на каждый блок по одному компоненту навесить
$.fn.checkComponentElem = function (className) {
  className = className || "initialized"
  let elem = this
  elem = elem
  //Есть компоненты, которые не надо инициализировать автоматически
    .not(".no-js-auto-init")
    //Уже инициализированные компоненты повторное не инициализируем
    .not(`.${className}`)
    //После инициализации добавляем компоненту класс initialized
    .addClass(className)
  return elem
}

$(document).on("ready", function () {
  $(document).foundation({
    equalizer: {
      // Specify if Equalizer should make elements equal height once they become stacked.
      equalize_on_stack: true
    }
  })

  //Если внутри эквалайзера находится картинка, которая еще не загружена
  //то одинаковая высота не будет установлена
  //Таймаут теоретически должен в большинстве случаев этот вопрос решить
  setTimeout(function () {
    $(document).foundation('equalizer', 'reflow')
  }, 1500)

  $(document).foundation('interchange', {
    named_queries: {
      retina_large: 'only screen and (min-width: 852px) and (-webkit-min-device-pixel-ratio: 2), ' +
        'only screen and (min-width: 852px) and (min--moz-device-pixel-ratio: 2), ' +
        'only screen and (min-width: 852px) and (-o-min-device-pixel-ratio: 2/1), ' +
        'only screen and (min-width: 852px) and (min-device-pixel-ratio: 2), ' +
        'only screen and (min-width: 852px) and (min-resolution: 192dpi), ' +
        'only screen and (min-width: 852px) and (min-resolution: 2dppx)'
    }
  })


  //Компомент верхнее меню
  class TopMenu {
    constructor(options) {
      let obj = this
      this.elem = options.elem

      this.elem.click(function (event) {
        if ($(event.target).closest(".menu-trigger").length &&
          obj.elem[0].contains(event.target)) {
          obj.elem.toggleClass("opened")
        }
      })
    }
  }

  new TopMenu({
    elem: $(".header-section")
  })

  //Компонент Подменю в меню
  class Submenu {
    constructor(options) {
      let obj = this
      this.elem = options.elem
      this.triggers = options.triggers

      this.triggers.click(function (event) {
        event.preventDefault()

        if (obj.elem.is(":visible")) {
          obj.hideMenu()
        } else {
          obj.showMenu()
          obj.elem.trigger("slidingDown")
        }
      })
    }

    showMenu() {
      let obj = this
      obj.triggers.addClass("active")
      obj.elem.slideDown()
    }

    hideMenu() {
      let obj = this
      obj.triggers.removeClass("active")
      obj.elem.slideUp()
    }

    getELem() {
      return this.elem
    }
  }

  let citiesSubmenu = new Submenu({
    // elem: $(".header-section ul.menu ul.submenu.cities"),
    // triggers: $(".header-section ul.menu li.submenu-trigger.change-city").add(".header-section .logo-and-city .city")
    elem: $(".js-city .city-wrapper"),
    triggers: $(".js-city .city-current")
  })
  let citiesSubmenuElem = citiesSubmenu.getELem()

  let eventsSubmenu = new Submenu({
    elem: $(".header-section ul.menu ul.submenu.events"),
    triggers: $(".header-section ul.menu li.submenu-trigger.events")
  })
  let eventsSubmenuElem = eventsSubmenu.getELem()

  let placesSubmenu = new Submenu({
    elem: $(".header-section ul.menu ul.submenu.places"),
    triggers: $(".header-section ul.menu li.submenu-trigger.places")
  })
  let placesSubmenuElem = placesSubmenu.getELem()

  citiesSubmenuElem.on("slidingDown", function () {
    eventsSubmenu.hideMenu()
    placesSubmenu.hideMenu()
  })

  eventsSubmenuElem.on("slidingDown", function () {
    placesSubmenu.hideMenu()
    citiesSubmenu.hideMenu()
  })

  placesSubmenuElem.on("slidingDown", function () {
    eventsSubmenu.hideMenu()
    citiesSubmenu.hideMenu()
  })

  //Компонент Главная форма поиска
  class MainSearchForm {
    constructor(options) {
      let obj = this
      this.elem = options.elem
      this.trigger = options.trigger

      this.trigger.click(function (event) {
        event.preventDefault()
        obj.toggleForm()
      })
    }

    toggleForm() {
      let obj = this
      obj.elem.toggleClass('__active')
    }
  }

  new MainSearchForm({
    elem: $(".search"),
    trigger: $(".search .js-toggler")
  })

  //Модальное окно выбора города
  class ChooseCityModal {
    constructor(options) {
      let obj = this
      this.elem = options.elem
      this.dropdown = this.elem.find(".dropdown")
      this.dropdownList = this.elem.find(".dropdown ul.dropdown-list")

      if (this.elem.hasClass("open-on-page-load")) {
        this.elem.foundation("reveal", "open")
      }

      this.elem.click(function (event) {
        if ($(event.target).closest(".trigger").length
          && obj.elem[0].contains(event.target)) {

          obj.triggerDropdown()
        }
      })

      this.elem.click(function (event) {
        let item = $(event.target).closest("ul.dropdown-list li")
        if (item.length
          && obj.elem[0].contains(event.target)) {

          obj.selectItem(item)
        }
      })

      $(document).click(function (event) {
        if (!obj.elem.find(".dropdown")[0].contains(event.target)) {
          obj.hideDropdown()
        }
      })
    }

    triggerDropdown() {
      let obj = this
      if (obj.dropdownList.is(":visible")) {
        obj.dropdown.removeClass("opened")
      } else {
        obj.dropdown.addClass("opened")
      }
    }

    hideDropdown() {
      let obj = this
      obj.dropdown.removeClass("opened")
    }

    selectItem(item) {
      let obj = this

      obj.elem.find(".trigger .text").html(item.text())
      obj.elem.find("input[type=hidden].value").val(item.data("value"))
      obj.hideDropdown()
    }
  }

  if ($("#modal-choose-city").length) {
    new ChooseCityModal({
      elem: $("#modal-choose-city")
    })
  }

  //Формы рассказать всем в модальных окнах - выбор файла
  class RevealModalBaseFormFile {
    constructor(options) {
      let obj = this
      this.elem = options.elem
      this.initialEmText = this.elem.find("em").text()

      this.elem.find("input[type=file]").on("change", function () {
        obj.setText($(this))
      })
    }

    setText(fileElem) {
      let value = fileElem.val()
      let em = fileElem.next("em")
      if (value) {
        let lastIndex = value.lastIndexOf('\\') + 1
        value = value.substr(lastIndex)
        em.text(value)
      } else {
        em.text(this.initialEmText)
      }
    }
  }


  $(".reveal-modal-base-form .file-label").each(function () {
    new RevealModalBaseFormFile({
      elem: $(this)
    })
  })

  //Формы рассказать всем в модальных окнах - отправка формы аяксом
  class RevealModalBaseFormAjax {
    constructor(options) {
      let obj = this
      this.elem = options.elem

      this.elem.ajaxForm({
        beforeSubmit: () => {
          this.elem.find("[type=submit]").attr("disabled", "disabled")
        },
        success: () => {
          this.elem.fadeOut(400, () => {
            this.elem.nextAll(".success-response").fadeIn()
          })
        },
        error: () => {
          this.elem.fadeOut(400, () => {
            this.elem.nextAll(".error-response").fadeIn()
          })
        }
      })
    }
  }


  $(".reveal-modal-base-form .base-form.ajax").each(function () {
    new RevealModalBaseFormAjax({
      elem: $(this)
    })
  })

  //Формы рассказать всем в модальных окнах - выбор даты
  class RevealModalBaseFormDatepicker {
    constructor(options) {
      this.elem = options.elem

      this.elem.datepicker({
        minDate: 0,
        maxDate: "+12M",
        changeMonth: true,
        changeYear: true
      })
    }
  }

  $(".reveal-modal-base-form .datepicker").each(function () {
    new RevealModalBaseFormDatepicker({
      elem: $(this)
    })
  })

  //Дропдаун в форме в модальном окне
  class RevealModalBaseFormDropdown {
    constructor(options) {
      let obj = this
      this.elem = options.elem
      this.dropdownElem = this.elem.find(".dropdown")
      this.textFieldElem = this.elem.find("input[type=text]")
      this.hiddenFieldElem = this.elem.find("input[type=hidden]")

      //Уберем проблемы из начала и окончания элементов дропдауна на всякий случай
      this.dropdownElem.find("li").each(function () {
        let text = $(this).text()
        text = $.trim(text)
        $(this).text(text)
      })

      this.textFieldElem.click(() => {
        this.showDropdown()

        //Если в поле не пусто, то отфильтруем варианты
        if (this.textFieldElem.val()) {
          this.filterList()
        }
      })

      $(document).click(function (event) {
        if (!obj.elem[0].contains(event.target)) {
          obj.hideDropdown()
          return
        }

        if ($(event.target).closest(obj.textFieldElem).length) return
        if ($(event.target).closest(obj.dropdownElem).length) return

        obj.hideDropdown()
      })

      this.elem.click(function (event) {
        let li = $(event.target).closest("li")
        if (li.length && obj.elem[0].contains(event.target)) {
          obj.chooseElem(li)
        }
      })

      this.textFieldElem.on("input", function () {
        obj.hiddenFieldElem.val("")
        obj.filterList()
      })

    }

    showDropdown() {
      this.dropdownElem.addClass("visible")
    }

    hideDropdown() {
      this.dropdownElem.removeClass("visible")
    }

    chooseElem(li) {
      let value = li.data("value")
      let text = li.text()
      this.hiddenFieldElem.val(value)
      this.textFieldElem.val(text)
      this.hideDropdown()
    }

    filterList() {
      let value = this.textFieldElem.val()
      let lis = this.dropdownElem.find("li")
      lis.show()

      value = value.toLowerCase()
      value = $.trim(value)

      lis.each(function () {
        let text = $(this).text().toLowerCase()
        if (text.indexOf(value) != 0) {
          $(this).hide()
        }
      })
    }
  }

  $(".reveal-modal-base-form .form-group.with-dropdown").each(function () {
    new RevealModalBaseFormDropdown({
      elem: $(this)
    })
  })


  //$("#modal-new-place-form").foundation("reveal", "open");

  // Smart App Banner
  $.smartbanner({
    title: 'LIKES',
    author: 'LIKES, LLC',
    icon: 'http://a1.mzstatic.com/us/r30/Purple30/v4/03/a9/e0/03a9e00f-1099-ebd6-0068-9fa41732ae63/icon175x175.jpeg',
    daysHidden: 0,
    daysReminder: 0
  })

  //Генерируем свое событие для инициализации компонентов
  $(document).trigger('page:loaded')
})
