$(document).on("ready", function () {
    if (!$("section.main.profile.edit").length) return;

    $("section.main.profile.edit input.date-of-birth").datepicker({
        changeMonth: true,
        changeYear: true,
        minDate: "-100Y",
        maxDate: 0,
        yearRange: "1900:2100"
    });

    //Компонент Карта
    class MapComponent {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;
            this.latField = this.elem.find(".lat");
            this.longField = this.elem.find(".long");

            if (this.latField.val() && this.longField.val()) {
                this.initialCoords = [this.latField.val(), this.longField.val()];
            } else {
                this.initialCoords = [55.76, 37.64];
                this.noInitialCoords = true;
            }
            this.setCoords(this.initialCoords);

            this.mapElem = this.elem.find("#profile-edit-map");

            this.map = new ymaps.Map("profile-edit-map", {
                center: this.initialCoords,
                zoom: this.mapElem.data("zoom"),
                controls: ['fullscreenControl', 'zoomControl']
            });
            this.map.behaviors.disable('scrollZoom');

            this.placemark = new ymaps.Placemark(this.initialCoords, {
                hintContent: ''
            }, {
                draggable: true,
                preset: 'islands#icon',
                iconColor: '#ff3a3a'
            });
            this.map.geoObjects.add(this.placemark);

            //Перетаскивание метки
            this.placemark.events.add("dragend", function (e) {
                let coords = obj.placemark.geometry.getCoordinates();
                obj.setCoords(coords);
            });

            //Клик по карте
            this.map.events.add("click", function (e) {
                let coords = e.get("coords");
                obj.setPlacemark(coords);
                obj.setCoords(coords);
            });
        }

        setCoords(coords) {
            this.latField.val(coords[0]);
            this.longField.val(coords[1]);
        }

        setPlacemark(coords) {
            this.placemark.geometry.setCoordinates(coords);
        }

        setCenter(coords) {
            this.map.setCenter(coords);
        }

        setParamsFromOutside(coords) {
            //Изменить из вне метку и цетнр карты можно только в том случаи,
            //если начальных координат не было
            if (!this.noInitialCoords) return;

            this.setPlacemark(coords);
            this.setCenter(coords);
        }

    }

    $(document).on("ym:ready", () => {
        let mapComponent;
        $("section.main.profile.edit .row.on-map .map").each(function () {
            mapComponent = new MapComponent({
                elem: $(this)
            });
        });

        //При изменении города меняем центр карты и метку, если первичные
        //координаты отсутсвуют. Также сразу при загрузке страницы запустим событие
        //для первоначального значения
        $("section.main.profile.edit select.city-select").change(function () {
            let option = $(this).find(":selected");
            let coords = [option.data("lat"), option.data("long")];

            //Если координат у опшина нет, то ничего делать не будем
            if(!coords[0] || !coords[0]) return;

            mapComponent.setParamsFromOutside(coords);
        }).trigger("change");
    });

    //Выбор файла
    class FileSelect {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;

            this.elem.find("input[type=file]").on("change", function () {
                obj.setText($(this));
            });
        }

        setText(fileElem) {
            let value = fileElem.val();
            let textElem = this.elem.find(".file-name");
            if (value) {
                let lastIndex = value.lastIndexOf('\\') + 1;
                value = value.substr(lastIndex);
                textElem.text(value);
            } else {
                textElem.text("");
            }
        }
    }

    let fileSelect;

    $("section.main.profile.edit .leftcol").each(function () {
        fileSelect = new FileSelect({
            elem: $(this)
        });
    });

    //Теги добавление/удаление
    class AddRemoveTag {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;
            this.addedContainer = this.elem.find(".added-items");
            this.toAddContainer = this.elem.find(".to-add-items");
            this.fieldElem = this.elem.find("input.search-field");

            this.fieldElem.on("input", () => {
                this.processChange();
            });

            this.elem.click((event) => {
                let tagToAdd = $(event.target).closest(".to-add-items .item");
                if (tagToAdd.length && this.elem[0].contains(event.target)) {
                    event.preventDefault();
                    this.addTag(tagToAdd);
                }
            });

            this.elem.click((event) => {
                let tagToRemove = $(event.target).closest(".added-items .item");
                if (tagToRemove.length && this.elem[0].contains(event.target)) {
                    event.preventDefault();
                    this.removeTag(tagToRemove);
                }
            });

        }

        processChange() {
            let val = this.fieldElem.val();
            $.get(this.elem.data("tags-url"),
                {
                    val: val,
                    page: 1,
                    limit: 1000
                },
                (data) => {
                    this.processData(data)
                });
        }

        processData(data) {
            this.toAddContainer.html("");

            data.forEach((item) => {
                let tagElem = $(`<a href="#" class="item">${item.title}</a>`).data("id", item.id);
                this.toAddContainer.append(tagElem);
            });
        }

        addTag(tag) {
            let tagElem = $(`
            <a href="#" class="item">
                ${tag.text()}
                <input type="hidden" name="tags[]" value="${tag.data('id')}">
            </a>`);
            this.addedContainer.append(tagElem);
        }

        removeTag(tag) {
            tag.remove();
        }
    }

    let addRemoveTag = new AddRemoveTag({
        elem: $("section.main.profile.edit .row.tags")
    });
});