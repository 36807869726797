class TitleAndMenuComponent {
    constructor(options) {
        this.elem = options.elem;
        this.ddElem = this.elem.find('.ct-dropdown');

        //Показ/скрытие ДД
        this.elem.click((event) => {
            if ($(event.target).closest('.ct-dd-trigger').length &&
                this.elem[0].contains(event.target)) {
               this.toggleDd();
            }
        });

        //Скрываем ДД если кликнули мимо него
        $(document).click((event) => {
            if (!this.ddElem[0].contains(event.target)) {
                this.hideDd();
            }
        });
    }

    toggleDd() {
        this.ddElem.toggleClass('__opened');
    }

    hideDd() {
        this.ddElem.removeClass('__opened');
    }
}

$(document).on("page:loaded", () => {
    $(".title-and-menu-component")
        .checkComponentElem("title-and-menu-component-initialized")
        .each((index, elem) => {
            new TitleAndMenuComponent({
                elem: $(elem)
            });
        });
});
