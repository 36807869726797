class SubscribeComponent {
    constructor(options) {
        let obj = this;
        this.elem = options.elem;

        if (Cookies.get('subscribed') === 'yes') {
            this.elem.hide();
            return
        }

        this.form = this.elem.find("form");
        this.field = this.elem.find(".ct-text-field");
        this.submit = this.elem.find(".ct-submit");
        this.close = this.elem.find(".ct-close");

        this.form.submit(function (e) {
            e.preventDefault();
            $.ajax({
                method: "POST",
                url: "/subscriptions",
                contentType: "application/json",
                dataType: 'html',
                data: JSON.stringify({email: obj.field.val()}),
                success: function (data) {
                    obj.successSubscribe();
                },
                error: function (data) {
                    obj.errorSubscribe();
                }
            });
        });

        this.close.click(function () {
            obj.hideSubscribe();
        });

    }

    successSubscribe() {
        this.elem.find(".ct-title").text("СПАСИБО");
        this.elem.find(".ct-subtitle").text("ПОДПИСКА ОФОРМЛЕНА");
        this.elem.find("input").removeClass("error").addClass("success");
        this.submit.hide();
        this.field.attr('disabled', 'true')
        Cookies.set('subscribed', 'yes', {expires: 10});
        setTimeout(this.hideSubscribe.bind(this), 5000);
    }

    errorSubscribe() {
        this.elem.find("input").addClass("error");
    }

    hideSubscribe() {
        this.elem.fadeOut();
    }

}

$(document).on("page:loaded", () => {
    $(".subscribe-component")
        .checkComponentElem("subscribe-component-initialized")
        .each(function () {
            new SubscribeComponent({
                elem: $(this)
            });
        });
});
