class UrlControl {
    constructor(options) {
        this.elem = options.elem;
        this.topElem = this.elem.find('.ct-uc-top');
        this.topTextElem = this.topElem.find('span');
        this.topStripeElem = this.topElem.find('em');
        this.currentItem = this.elem.find(".ct-uc-item").first();
        this.pageTitleELem = $('html').find('title');
        this.topFormElem = $('body').find('.main-search-form-container .outer-container');

        $(window).on("scroll resize", () => {
            this.processScroll();
        });

        $(window).scroll();

        //Показ материала полностью
        this.elem.click((event) => {
            let showMoreElem = $(event.target).closest('.ct-uc-read-more span');
            if (showMoreElem.length && this.elem[0].contains(event.target)) {
                this.showMore(showMoreElem);
            }
        });

        //Проверим первый элемент на высоту
        this.checkItemHeight(this.currentItem);

        //Когда материал подгружается по доскролу проверяем его на высоту
        $(document).on('is:loaded', () => {
            let lastItem = this.elem.find(".ct-uc-item").last();
            this.checkItemHeight(lastItem);
        });

        //Извне можно раскрыть опредленный элемент
        this.elem.on('uc:expand_elem', (event, itemElem) => {
           if(itemElem.hasClass('__opened')) {
               return;
           }

           this.showMore(itemElem.find('.ct-uc-read-more span'));
        });
    }

    checkItemHeight(item) {
        if (item.hasClass('__opened')) {
            return;
        }

        let maxHeight = parseInt(item.css('max-height'));
        let height = item.height();

        if (maxHeight > height) {
            item.addClass('__opened');
        }
    }

    showMore(showMoreELem) {
        showMoreELem.closest('.ct-uc-item').addClass('__opened');
    }

    processScroll() {
        //Покажем/скроем верхний элемент в зависимости от его расположения
        //относительно формы поиска
        this.showHideTopElem();

        //Определим не ушел ли активный элемент из поля видимости страницы
        let windowTop = pageYOffset;
        let windowBottom = windowTop + document.documentElement.clientHeight;

        let itemBox = this.currentItem[0].getBoundingClientRect();

        let itemTop = itemBox.top + pageYOffset;
        let itemBottom = itemBox.bottom + pageYOffset;

        //Половина экрана плюс еще немного
        let halfScreen = document.documentElement.clientHeight / 2 + 5;

        //Уехали ниже активного элемента
        if (windowBottom > itemBottom + halfScreen) {
            //Переключимся на следующий материал, если он есть
            let tmp = this.currentItem.nextAll(".ct-uc-item").first();
            if (tmp.length) {
                this.setCurrentMat(tmp);
                this.startAnimation();
            }

        } else if (windowTop + halfScreen < itemTop) {
            //Уехали выше активного элемента
            //Переключимся на предыдущий материал
            let tmp = this.currentItem.prevAll(".ct-uc-item").first();
            if (tmp.length) {
                this.setCurrentMat(tmp);
            }
        }

        //Активный материал мог смениться
        itemBox = this.currentItem[0].getBoundingClientRect();

        let stripeWidth = (halfScreen - itemBox.top) / (itemBox.bottom - itemBox.top) * 100;
        if (stripeWidth < 0) {
            stripeWidth = 0;
        }

        if (stripeWidth > 100) {
            stripeWidth = 100;
        }

        this.topStripeElem.css('width', `${stripeWidth}%`);
    }

    showHideTopElem() {
        if(!this.topFormElem[0]) return;
        //Это нужно только на десктопе
        if (document.documentElement.clientWidth < 852) {
            this.topElem.show();
            return;
        }

        if (this.topFormElem[0].getBoundingClientRect().bottom < 0) {
            this.topElem.show();
        } else {
            this.topElem.hide();
        }
    }

    setCurrentMat(current) {
        this.currentItem = current;
        history.replaceState(null, null, this.currentItem.data("url"));
        this.topTextElem.html(this.currentItem.data('title'));
        this.pageTitleELem.html(this.currentItem.data('title'));
        this.elem.trigger("aps:url_change");

        this.topTextElem.addClass('animated fadeIn');
        this.topTextElem.on('animationend', () => {
            this.topTextElem.removeClass('animated fadeIn');
        });
    }

    startAnimation() {
        let animateELem = this.currentItem.find('.ct-uc-animate');

        animateELem.addClass('__animated');
        animateELem.on('animationend', () => {
            animateELem.removeClass('__animated');
        });
    }
}

$(document).on("page:loaded", function () {
    $(".url-control-component")
        .checkComponentElem("url-control-component-initialized")
        .each(function () {
            new UrlControl({
                elem: $(this)
            });
        });
});