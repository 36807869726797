$(document).on("ready", function () {
    //Кабинет пользователя в навигации наверху страницы
    class UserLk {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;
            this.submenuElem = this.elem.find(".user-lk-submenu");

            this.elem.click(function (event) {
                if ($(event.target).closest(".user-lk-trigger").length &&
                    obj.elem[0].contains(event.target)) {
                    event.preventDefault();
                    obj.triggerSubmenu();
                }
            });

            $(document).click(function (event) {
                if (obj.elem[0].contains(event.target)) return;

                obj.elem.removeClass("active");
            });
        }

        triggerSubmenu() {
            this.elem.toggleClass("active");
        }
    }

    $("nav.main .user-lk.authorized")
        .checkComponentElem("user-lk-initialized")
        .each(function () {
            new UserLk({
                elem: $(this)
            });
        });
});