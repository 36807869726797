$(document).on("ready", function () {
  if (!$("section.main.search.index").length) return

  //Показ/скрытие фильтров
  class SearchFiltersFolding {
    constructor(options) {
      let obj = this
      this.elem = options.elem
      this.filterElems = this.elem.find(".filter-block")
      this.sortElem = this.elem.find(".sort")

      this.elem.click(function (event) {
        if ($(event.target).closest(".filters-trigger").length &&
          obj.elem[0].contains(event.target)) {
          event.preventDefault()
          obj.triggerFilters()
        }
      })

      this.elem.click(function (event) {
        let filterTrigger = $(event.target).closest(".filter-heading")
        if (filterTrigger.length && obj.elem[0].contains(event.target)) {
          obj.triggerFilter(filterTrigger.closest(".filter-block"))
        }
      })

      this.elem.click(function (event) {
        if ($(event.target).closest(".sort-trigger").length &&
          obj.elem[0].contains(event.target)) {
          obj.triggerSort()
        }
      })

      this.elem.click(function (event) {
        let ddItem = $(event.target).closest(".sort-dropdown-item")

        if (ddItem.length && obj.elem[0].contains(ddItem[0])) {
          obj.selectSortItem(ddItem)
        }
      })

      this.elem.click(function (event) {
        let filterItemsExpandTriggerElem = $(event.target).closest(".filter-items-with-expand .more")

        if (filterItemsExpandTriggerElem.length && obj.elem[0].contains(event.target)) {
          obj.triggerExpandElem(filterItemsExpandTriggerElem.closest(".filter-items-with-expand"))
        }
      })

      $(document).click(function (event) {
        if (obj.sortElem[0].contains(event.target)) return

        obj.sortElem.removeClass("active")
      })
    }

    triggerFilters() {
      let obj = this
      obj.elem.toggleClass("active")
    }

    triggerFilter(filterElem) {
      let obj = this
      filterElem.toggleClass("active")
    }

    triggerSort() {
      let obj = this
      obj.sortElem.toggleClass("active")
    }

    selectSortItem(item) {
      let obj = this
      obj.sortElem.removeClass("active").data("sort", item.data("value"))
      obj.sortElem.find(".sort-trigger .text").text(item.text())
    }

    triggerExpandElem(elem) {
      elem.toggleClass("active")
    }
  }

  new SearchFiltersFolding({
    elem: $("section.main.search.index")
  })

  //на странице и по Показать больше часть скрытых показывалась
  //Подгрузка тегов
  class GetMoreTags {
    constructor(options) {
      let obj = this
      this.elem = options.elem

      this.elem.click(function (event) {
        if ($(event.target).closest(".tags-more a").length &&
          obj.elem[0].contains(event.target)) {
          event.preventDefault()

          if (obj.requestInProgress) return

          obj.requestInProgress = true
          obj.loadMoreTags()
        }
      })

      this.page = 2
      this.limit = 10
    }

    loadMoreTags() {
      let url = this.elem.data("more-tags-url")
      $.getJSON(url, `page=${this.page}&limit=${this.limit}`, data => {
        //Последняя страница
        if (!data.length) {
          this.elem.find(".tags-more").remove()
          return
        }

        data.forEach(item => {
          let tagElem = $(`
                   <a href="#"
                      class="tags-item"
                      data-search-option
                      data-search-category="what"
                      data-search-key="tag_id"
                      data-search-value="${item.id}">${item.title}</a>`)

          this.elem.find(".tags-more").before(tagElem)
        })

        this.page += 1
        this.requestInProgress = false
      })
    }
  }

  //Пока класс GetMoreTags не используем - не используем подгрузку
  /*
   let getMoreTags = new GetMoreTags({
   elem: $("section.main.search.index .filter-block.tags")
   });
   */


  //Этот класс по клику на Показать больше показывает больше и больше тегов
  //все теги изначально должны быть загружены на страницу
  class ShowMoreTags {
    constructor(options) {
      let obj = this
      this.elem = options.elem

      this.limit = this.elem.data("limit") || 10
      this.tags = this.elem.find(".tags-item")

      this.tags.slice(0, this.limit).addClass("visible")

      if (this.tags.length > this.limit) {
        this.elem.find(".tags-more").addClass("visible")
      }

      this.elem.click(function (event) {
        if ($(event.target).closest(".tags-more a").length &&
          obj.elem[0].contains(event.target)) {
          event.preventDefault()
          obj.showMore()
        }
      })
    }

    showMore() {
      let visibleNum = this.tags.filter(".visible").length
      this.tags.slice(0, visibleNum + this.limit).addClass("visible")

      if (visibleNum + this.limit >= this.tags.length) {
        this.elem.find(".tags-more").removeClass("visible")
      }
    }
  }

  new ShowMoreTags({
    elem: $("section.main.search.index .filter-block.tags .tags-items")
  })

  //Сборщик параметров поиска
  class SearchCollector {
    constructor(options) {
      let obj = this
      this.elem = options.elem
      this.queryInputElem = this.elem.find(".search-form input.query")
      this.queryContainerElem = this.elem.find(".search-form .query-container")

      //Шаблон для элемента внутри блока с инпутом
      this.selectedElemTemplate = $("#search-selected-elem-template").html()
      this.selectedElemTemplate = Handlebars.compile(this.selectedElemTemplate)

      //Календарь для дат
      this.elem.find("[data-search-option-date]").datepicker({
        minDate: "-12M",
        maxDate: "+12M",
        changeMonth: true,
        changeYear: true,
        dateFormat: "yy-mm-dd"
      })

      //Добавим стандартную опцию
      this.elem.click(function (event, doNotTriggerForm) {
        let optionElem = $(event.target).closest("[data-search-option]")
        if (optionElem.length &&
          obj.elem[0].contains(event.target)) {
          event.preventDefault()
          if (optionElem.hasClass("active")) {
            obj.removeOption(optionElem)
          } else {
            obj.addOption(optionElem)
          }

          if (!doNotTriggerForm) {
            obj.triggerSearchForm()
          }

          obj.setQueryElemWidth()
        }
      })

      //Добавим опцию Рядом со мной
      this.elem.click(function (event, doNotTriggerForm) {
        let optionElem = $(event.target).closest("[data-search-option-geo]")
        if (optionElem.length &&
          obj.elem[0].contains(event.target)) {
          event.preventDefault()

          if (optionElem.hasClass("active")) {
            obj.removeGeoOption()

            if (!doNotTriggerForm) {
              obj.triggerSearchForm()
            }
          } else {
            //Добавление Рядом со мной - асинхронная операция
            //поэмтоу мы форму отправляем на сервер по ее завершении в самой функции
            obj.addGeoOption()
          }

          obj.setQueryElemWidth()
        }
      })

      //Добавим опцию даты От До
      this.elem.change(function (event, doNotTriggerForm) {
        let selectElem = $(event.target).closest("[data-search-option-date]")
        if (selectElem.length &&
          obj.elem[0].contains(event.target)) {
          event.preventDefault()
          obj.addDateOption(selectElem)

          if (!doNotTriggerForm) {
            obj.triggerSearchForm()
          }

          obj.setQueryElemWidth()
        }
      })

      //Для детей, Лайк рекомендует
      this.elem.change(function (event, doNotTriggerForm) {
        let checkboxElem = $(event.target).closest("[data-search-option-checkbox]")
        if (checkboxElem.length &&
          obj.elem[0].contains(event.target)) {
          event.preventDefault()
          obj.addCheckboxOption(checkboxElem)

          if (!doNotTriggerForm) {
            obj.triggerSearchForm()
          }

          obj.setQueryElemWidth()
        }
      })

      //Удалим опцию
      this.elem.click(function (event, doNotTriggerForm) {
        let removeElem = $(event.target).closest(".search-form .query-container .selected .remove")
        let selectedElem = removeElem.closest(".selected")
        if (removeElem.length &&
          obj.elem[0].contains(event.target)) {
          event.preventDefault()

          //Отдельно обработаем удаление опции Рядом со мной
          if (selectedElem.data("search-category") == "where" &&
            selectedElem.data("search-key") == "geo") {
            obj.removeGeoOption()
          } else if (selectedElem.data("search-category") == "when" &&
            (selectedElem.data("search-key") == "date_from" || selectedElem.data("search-key") == "date_to")) {
            //Удалим опцию От До
            obj.removeFromToOption(selectedElem)
          } else if (selectedElem.data("search-category") == "like-recommends" ||
            selectedElem.data("search-category") == "for-children") {
            //Удалим опицю чекбокс
            obj.removeCheckboxOption(selectedElem)
          } else {
            //Удалим обычную опцию
            obj.removeOption(selectedElem, false)
          }

          if (!doNotTriggerForm) {
            obj.triggerSearchForm()
          }

          obj.setQueryElemWidth()
        }
      })

      //Сбросим все параметры
      this.elem.click(function (event, doNotTriggerForm) {
        if ($(event.target).closest(".buttons-container [type=reset]").length &&
          obj.elem[0].contains(event.target)) {
          obj.resetAll()

          if (!doNotTriggerForm) {
            obj.triggerSearchForm()
          }

          obj.setQueryElemWidth()
        }

      })
    }

    setQueryElemWidth() {
      let containerWidth = this.queryContainerElem.width()
      let selectedElemsWidth = 0
      this.queryContainerElem.find('.selected').each((index, elem) => {
        selectedElemsWidth += $(elem).outerWidth(true)
      })

      if ((containerWidth - selectedElemsWidth) < containerWidth / 2) {
        this.queryInputElem.css('width', '100%')

      } else {
        this.queryInputElem.css('width', `${containerWidth - selectedElemsWidth - 6}px`)
      }
    }

    addOption(optionElem) {
      let obj = this

      let text
      if (optionElem.data("search-text")) {
        text = optionElem.data("search-text")
      } else {
        text = optionElem.text()
      }

      let optionElemInQueryContainer = obj.getOptionElem(text)

      let searchCategory = optionElem.data("search-category")
      let searchKey = optionElem.data("search-key")

      let additionalClass
      if (searchCategory == "where" || searchCategory == "rates" || searchCategory == "price") {
        additionalClass = "green"
      } else if (searchKey == "tag_id") {
        additionalClass = "color"
      }
      optionElemInQueryContainer.addClass(additionalClass)

      //Ставим именно атрибуты, т.к. по ним потом будет происходить поиск
      optionElemInQueryContainer.attr("data-search-category", searchCategory)
      optionElemInQueryContainer.attr("data-search-key", searchKey)
      optionElemInQueryContainer.attr("data-search-value", optionElem.data("search-value"))

      //Вспомогательная функция для взаимоисключений
      function exceptionHelper(params) {
        params.forEach((param) => {
          let findingCategory = param.category
          let findingKey = param.key

          if (searchCategory == findingCategory && searchKey == findingKey) {
            obj.queryContainerElem.find(".selected")
              .filter(`[data-search-category='${findingCategory}']`)
              .filter(`[data-search-key='${findingKey}']`)
              .remove()

            optionElem.siblings(`.filter-item[data-search-key='${findingKey}']`)
              .removeClass("active")
          }
        })
      }

      exceptionHelper([
        {category: "what", key: "module"},
        {category: "what", key: "event_category_id"},
        {category: "what", key: "place_category_id"},
        {category: "where", key: "city_area_id"},
        {category: "when", key: "pattern"},
        {category: "price", key: "max"},
        {category: "rates", key: "no"}
      ])

      //Если выбрали район, то сбросим Рядом со мной
      if (searchCategory == "where" && searchKey == "city_area_id") {
        obj.removeGeoOption()
      }

      //Если выбрали категорию даты (сегодня, завтра и т.д.)
      //то сбросим диапазон дат
      if (searchCategory == "when" && searchKey == "pattern") {
        obj.queryContainerElem.find(".selected")
          .filter("[data-search-category='when']")
          .filter("[data-search-key='date_from']")
          .remove()

        obj.queryContainerElem.find(".selected")
          .filter("[data-search-category='when']")
          .filter("[data-search-key='date_to']")
          .remove()

        obj.elem
          .find("[data-search-option-date-from]")
          .add("[data-search-option-date-to]")
          .val("")
      }

      //Если вырали Бесплатно, то уберем цены
      if (searchCategory == "price" && searchKey == "free") {
        obj.queryContainerElem.find(".selected")
          .filter(`[data-search-category='price']`)
          .filter(`[data-search-key='max']`)
          .remove()

        optionElem.siblings(`.filter-item[data-search-key='max']`)
          .removeClass("active")
      }

      //Если выбрали Цена до, то снимем Бесплатно
      if (searchCategory == "price" && searchKey == "max") {
        obj.queryContainerElem.find(".selected")
          .filter(`[data-search-category='price']`)
          .filter(`[data-search-key='free']`)
          .remove()

        optionElem.siblings(`.filter-item[data-search-key='free']`)
          .removeClass("active")
      }

      obj.queryInputElem.before(optionElemInQueryContainer)

      optionElem.addClass("active")
    }

    addGeoOption() {
      if (!Modernizr.geolocation) return

      let obj = this
      let optionELem = obj.elem.find(".filter-item.near-me")

      if (optionELem.hasClass("disabled")) return

      optionELem.addClass("disabled")

      navigator.geolocation.getCurrentPosition(function (position) {
        let optionElemInQueryContainer = obj.getOptionElem("Рядом со мной").addClass("green")
        optionElemInQueryContainer
        //Тут добавляем именно атрибуты, т.к. они потом понадобятся для поиска элемента через  DOM
          .attr("data-search-category", "where")
          .attr("data-search-key", "geo")
          .data({
            "search-value": {
              "latitude": position.coords.latitude,
              "longitude": position.coords.longitude
            }
          })

        obj.queryInputElem.before(optionElemInQueryContainer)

        optionELem.addClass("active").removeClass("disabled")

        //Удалим опции с районами
        obj.queryContainerElem.find(".selected")
          .filter(`[data-search-category='where']`)
          .filter(`[data-search-key='city_area_id']`)
          .remove()
        obj.elem.find("[data-search-option]")
          .filter(`[data-search-category='where']`)
          .filter(`[data-search-key='city_area_id']`)
          .removeClass("active")

        obj.triggerSearchForm()
        obj.setQueryElemWidth()
      })
    }

    addDateOption(selectElem) {
      let obj = this
      let text, search_key
      if (selectElem.is("[data-search-option-date-from]")) {
        text = "От: "
        search_key = "date_from"
      } else {
        text = "До: "
        search_key = "date_to"
      }
      text += selectElem.val()

      let optionElemInQueryContainer = obj.getOptionElem(text)
      optionElemInQueryContainer
        .attr("data-search-category", "when")
        .attr("data-search-key", search_key)
        .data({"search-value": selectElem.val()})

      //Удалим из текстового поля все элементы Когда? базовые
      obj.queryContainerElem.find(".selected")
        .filter("[data-search-category='when']")
        .filter("[data-search-key='pattern']")
        .remove()

      //Удалим прошлый элемент От или До
      obj.queryContainerElem.find(".selected")
        .filter("[data-search-category='when']")
        .filter(`[data-search-key="${search_key}"]`)
        .remove()

      obj.elem.find(".filter-block.when .filter-item").removeClass("active")

      //Если выбрали дату, а не сняли выбор, то добавим элемент
      if (selectElem.val()) {
        obj.queryInputElem.before(optionElemInQueryContainer)
      }

      //Если выбрали От, то сделаем До активным
      if (selectElem.is("[data-search-option-date-from]") && selectElem.val()) {
        obj.elem.find("[data-search-option-date-to]").removeAttr("disabled")
      }

      //Если сняли выбор с От, то сделаем До неактивным, сбросим его и удалим
      //поисковой строки дату До
      if (selectElem.is("[data-search-option-date-from]") && !selectElem.val()) {
        obj.elem.find("[data-search-option-date-to]").val("").attr("disabled", "disabled")

        obj.queryContainerElem.find(".selected")
          .filter("[data-search-category='when']")
          .filter("[data-search-key='date_to']")
          .remove()
      }
    }

    addCheckboxOption(checkboxElem) {
      let obj = this
      let text, search_category
      if (checkboxElem.is("[data-search-option-checkbox-recommends]")) {
        text = "LIKES"
        search_category = "like-recommends"
      } else {
        text = "Для детей"
        search_category = "for-children"
      }

      let optionElemInQueryContainer = obj.getOptionElem(text)
      optionElemInQueryContainer.attr("data-search-category", search_category)

      obj.queryContainerElem.find(".selected")
        .filter(`[data-search-category="${search_category}"]`)
        .remove()

      if (checkboxElem.is(":checked")) {
        obj.queryInputElem.before(optionElemInQueryContainer)
      }
    }

    //Удалять можно либо передавая элемент из блока фильтров, либо
    //элемент из блока где текстовое поле
    removeOption(elem, byFilterElem = true) {
      let obj = this
      let filterElem, fieldBlockElem
      if (byFilterElem) {
        filterElem = elem
        fieldBlockElem = this.elem
          .find(".search-form .query-container .selected")
          .filter(`[data-search-category="${filterElem.data("search-category")}"]`)
          .filter(`[data-search-key="${filterElem.data("search-key")}"]`)
          .filter(`[data-search-value="${filterElem.data("search-value")}"]`)

      } else {
        fieldBlockElem = elem
        filterElem = this.elem
          .find(".filter-block .filter-content .filter-item")
          .add(this.elem.find(".filter-block .filter-content .tags-item"))
          .filter(`[data-search-category="${fieldBlockElem.data("search-category")}"]`)
          .filter(`[data-search-key="${fieldBlockElem.data("search-key")}"]`)
          .filter(`[data-search-value="${fieldBlockElem.data("search-value")}"]`)
      }

      fieldBlockElem.remove()
      filterElem.removeClass("active")
    }

    removeGeoOption() {
      let obj = this

      obj.elem.find(".search-form .query-container .selected")
        .filter("[data-search-category='where']")
        .filter("[data-search-key='geo']")
        .remove()

      obj.elem.find(".filter-item.near-me").removeClass("active")
    }

    removeCheckboxOption(option) {
      let obj = this

      if (option.data("search-category") == "like-recommends") {
        obj.elem.find("[data-search-option-checkbox-recommends]").removeAttr("checked")
      } else {
        obj.elem.find("[data-search-option-checkbox-children]").removeAttr("checked")
      }

      option.remove()
    }

    removeFromToOption(option) {
      let obj = this

      if (option.data("search-key") == "date_from") {
        obj.queryContainerElem.find(".selected")
          .filter("[data-search-category='when']")
          .filter("[data-search-key='date_from']")
          .remove()

        obj.elem.find("[data-search-option-date-from]").val("")
      } else {
        obj.queryContainerElem.find(".selected")
          .filter("[data-search-category='when']")
          .filter("[data-search-key='date_to']")
          .remove()

        obj.elem.find("[data-search-option-date-to]").val("")
      }
    }

    getOptionElem(text) {
      return $(this.selectedElemTemplate({text: text}))
    }

    //Сбрасываем все параметры
    resetAll() {
      let obj = this

      obj.queryContainerElem.find(".selected").remove()
      obj.elem.find(".filter-item").add(".tags-item").removeClass("active")
      obj.elem.find("[data-search-option-date]").val("")
      obj.elem.find("[data-search-option-checkbox]").removeAttr("checked")
    }

    //Функция отправляет форму поиска
    triggerSearchForm() {
      this.elem.find(".search-form").trigger("submit")
    }
  }

  new SearchCollector({
    elem: $("section.main.search.index")
  })

  //Формировщик запроса
  class SearchCompiler {
    constructor(options) {
      let obj = this
      this.elem = options.elem
      this.formElem = this.elem.find("form.search-form")
      this.queryContainerElem = this.formElem.find(".query-container")
      this.showMoreElem = this.elem.find(".show-more-results")

      //Пагинация при отправке запроса
      this.page = 1
      this.limit = 2

      //Город
      this.cityId = this.elem.data("search-city-id")

      //Первоначальное значение сортировки
      this.sortBy = this.elem.find(".rightcol .sort").data("sort")

      //В режиме тестирования отправляем гетом на тестовый адрес, чтобы получить json
      if (this.elem.data("test-mode") == "yes") {
        this.testMode = true
      } else {
        this.testMode = false
      }

      //Шаблоны для вывода результатов
      this.basicResultTemplate = $("#search-result-basic-template").html()
      this.basicResultTemplate = Handlebars.compile(this.basicResultTemplate)

      this.movieResultTemplate = $("#search-result-movie-template").html()
      this.movieResultTemplate = Handlebars.compile(this.movieResultTemplate)

      this.albumResultTemplate = $("#search-result-album-template").html()
      this.albumResultTemplate = Handlebars.compile(this.albumResultTemplate)

      this.superEventResultTemplate = $("#search-result-super-event-template").html()
      this.superEventResultTemplate = Handlebars.compile(this.superEventResultTemplate)


      //Отправка запроса - сабмит формы
      this.formElem.submit(function (event) {
        event.preventDefault()
        if (obj.requestInProgress) return

        //Когда мы сабмитим форму, то всегда отправляем
        // первый запрос с точки зрения пагинации
        obj.page = 1
        obj.showMoreElem.removeClass("active")

        obj.sendRequest(obj.compileParams())

        //поместим параметры в УРЛ
        let urlParams = obj.compileParams()
        delete urlParams.common.city_id
        delete urlParams.common.page
        delete urlParams.common.limit
        urlParams = JSON.stringify(urlParams)
        urlParams = $.queryStringStringify({
          search_params: urlParams
        })
        history.replaceState(null, null, `${location.pathname}?${urlParams}`)
      })

      //Отправка запроса - Показать больше
      this.elem.click(function (event) {
        if ($(event.target).closest(".show-more-results").length &&
          obj.elem[0].contains(event.target)) {
          event.preventDefault()
          if (obj.requestInProgress) return

          obj.sendRequest(obj.compileParams(), false)
        }
      })
    }

    compileParams() {
      let obj = this

      //Лайк рекомендует
      let recommend = false
      if (obj.queryContainerElem.find(".selected").filter(`[data-search-category="like-recommends"]`).length) {
        recommend = true
      }

      //Для детей
      let for_kids = false
      if (obj.queryContainerElem.find(".selected").filter(`[data-search-category="for-children"]`).length) {
        for_kids = true
      }

      //Сортировка сейчас выставляется автоматически
      //obj.sortBy = obj.elem.find(".rightcol .sort").data("sort");
      obj.sortBy = "date"
      if (obj.formElem.find("input.query").val() != "") {
        obj.sortBy = "relevance"
      }

      let placesNum = obj.queryContainerElem.find(".selected")
        .filter("[data-search-category='what']")
        .filter("[data-search-key='place_category_id']")
        .length
      if (placesNum) {
        obj.sortBy = "views"
      }

      placesNum = obj.queryContainerElem.find(".selected")
        .filter("[data-search-category='what']")
        .filter("[data-search-key='place_id']")
        .length
      if (placesNum) {
        obj.sortBy = "views"
      }

      let compiledParams = {
        common: {
          city_id: obj.cityId,
          page: obj.page,
          limit: obj.limit,
          sort_by: obj.sortBy,
          search_query: obj.formElem.find("input.query").val().trim(),
          recommend: recommend,
          for_kids: for_kids
        },
        extra: []
      }

      //Сформируем блоки экстра-параметров
      //Что (все кроме тегов)
      obj.queryContainerElem.find(".selected")
        .filter("[data-search-category='what']")
        .not("[data-search-key='tag_id']")
        .each(function () {
          let paramsElem = {
            category: "what",
            key: $(this).data("search-key"),
            value: $(this).data("search-value")
          }

          if (paramsElem.key == "place_id") {
            paramsElem.description = $(this).data("search-description")
          }

          compiledParams.extra.push(paramsElem)
        })

      //Где
      obj.queryContainerElem.find(".selected")
        .filter("[data-search-category='where']")
        .each(function () {
          compiledParams.extra.push({
            category: "where",
            key: $(this).data("search-key"),
            value: $(this).data("search-value")
          })
        })

      //Когда - обычные элементы
      obj.queryContainerElem.find(".selected")
        .filter("[data-search-category='when']")
        .filter("[data-search-key='pattern']")
        .each(function () {
          compiledParams.extra.push({
            category: "when",
            key: $(this).data("search-key"),
            value: $(this).data("search-value")
          })
        })

      //Когда От
      let dateFrom = obj.queryContainerElem.find(".selected")
        .filter("[data-search-category='when']")
        .filter("[data-search-key='date_from']")

      //Когда До
      let dateTo = obj.queryContainerElem.find(".selected")
        .filter("[data-search-category='when']")
        .filter("[data-search-key='date_to']")

      if (dateFrom.length && dateTo.length) {
        compiledParams.extra.push({
          category: "when",
          key: "range",
          value: {
            from: dateFrom.data("search-value"),
            to: dateTo.data("search-value")
          }
        })
      } else if (dateFrom.length) {
        compiledParams.extra.push({
          category: "when",
          key: "date",
          value: dateFrom.data("search-value")
        })
      } else if (dateTo.length) {
        compiledParams.extra.push({
          category: "when",
          key: "date",
          value: dateTo.data("search-value")
        })
      }

      //Рейтинг
      obj.queryContainerElem.find(".selected")
        .filter("[data-search-category='rates']")
        .each(function () {
          compiledParams.extra.push({
            category: "rates",
            key: null,
            value: $(this).data("search-value")
          })
        })

      //Теги
      obj.queryContainerElem.find(".selected")
        .filter("[data-search-category='what']")
        .filter("[data-search-key='tag_id']")
        .each(function () {
          compiledParams.extra.push({
            category: "what",
            key: $(this).data("search-key"),
            value: $(this).data("search-value")
          })
        })

      //Цена
      obj.queryContainerElem.find(".selected")
        .filter("[data-search-category='price']")
        .each(function () {
          compiledParams.extra.push({
            category: "price",
            key: $(this).data("search-key"),
            value: $(this).data("search-value")
          })
        })

      //Готово. Возвращаем
      return compiledParams
    }

    sendRequest(params, firstPage = true) {
      let obj = this
      let url = obj.elem.data("search-results-url")
      obj.elem.find(".rightcol").addClass("disabled")
      obj.requestInProgress = true

      //console.log(params);

      if (obj.testMode) {
        $.get(url, "id=1", function (data) {
          obj.processResponse(data, firstPage)
        })
      } else {
        //TODO обработку ошибок добавить
        $.ajax({
          contentType: "application/json",
          data: JSON.stringify(params),
          method: "POST",
          success: function (data) {
            obj.processResponse(data, firstPage)
          },
          url: url
        })
      }
    }

    processResponse(data, firstPage) {
      let obj = this
      let resultsContainer = obj.elem.find(".rightcol .results")

      if (firstPage) {
        resultsContainer.html("")
      }

      console.log(data)

      data.forEach(function (item) {
        let templateData = {}

        switch (item.type) {
          case "article":
            templateData.date = item.data.human_created_at
            templateData.name = item.data.title
            templateData.url = item.data.url
            templateData.image = item.data.image

            resultsContainer.append(obj.basicResultTemplate(templateData))
            break

          case "packets":
            templateData.date = item.data.human_created_at
            templateData.name = item.data.title
            templateData.url = item.data.url
            templateData.image = item.data.image

            resultsContainer.append(obj.basicResultTemplate(templateData))
            break

          case "event":
            templateData.for_children = item.data.for_kids
            templateData.recommend = item.data.recommend
            templateData.past = item.data.past
            if (item.data.event_category) {
              templateData.type = item.data.event_category.title
              templateData.type_icon = "e-p-types-component " + item.data.event_category.icon
              templateData.type_url = item.data.event_category.url
            }
            templateData.date = item.data.human_occur_at
            if (item.data.place) {
              templateData.place = item.data.place.title
              templateData.place_url = item.data.place.url
              if (item.data.place.place_category) {
                templateData.place_category = item.data.place.place_category.title
                templateData.place_category_url = item.data.place.place_category.url
              }
            }
            templateData.name = item.data.title
            templateData.url = item.data.url
            templateData.image = item.data.image
            if (item.data.city_area) {
              templateData.location = item.data.city_area.title
              templateData.location_url = item.data.city_area.url
            }
            templateData.r_v_l = item.data.rates || item.data.views
            templateData.rating = item.data.rates
            templateData.views = item.data.views

            //Супер событие выводим в своем спецшаблоне только если оно не прошло
            if (item.data.is_super && !item.data.past) {
              resultsContainer.append(obj.superEventResultTemplate(templateData))
            } else {
              resultsContainer.append(obj.basicResultTemplate(templateData))
            }
            break

          case "place":
            templateData.for_children = item.data.for_kids
            templateData.recommend = item.data.recommend
            if (item.data.place_category) {
              templateData.type = item.data.place_category.title
              templateData.type_url = item.data.place_category.url
              templateData.type_icon = "e-p-types-component " + item.data.place_category.icon
            }
            templateData.name = item.data.title
            templateData.url = item.data.url
            templateData.image = item.data.image
            templateData.location = item.data.address
            templateData.r_v_l = item.data.rates || item.data.views
            templateData.rating = item.data.rates
            templateData.views = item.data.views

            resultsContainer.append(obj.basicResultTemplate(templateData))
            break

          case "benefit":
            let benefit_type, benefit_icon
            if (item.data.kind == 1) {
              benefit_type = "Купон"
              benefit_icon = "benefit-kupon"
            } else if (item.data.kind == 2) {
              benefit_type = "Скида"
              benefit_icon = "benefit-discount"
            } else if (item.data.kind == 3) {
              benefit_type = "Подарок"
              benefit_icon = "benefit-gift"
            }

            templateData.type = benefit_type
            templateData.type_icon = benefit_icon
            templateData.type_url = item.data.kind_url
            templateData.date = item.data.human_finish_at
            if (item.data.place) {
              templateData.place = item.data.place.title
              templateData.place_url = item.data.place.url
            }
            templateData.name = item.data.title
            templateData.url = item.data.url
            templateData.image = item.data.image

            resultsContainer.append(obj.basicResultTemplate(templateData))
            break

          case "movie":
            templateData.embed_code = item.data.embed_code
            templateData.id = item.data.id
            templateData.name = item.data.title
            templateData.url = item.data.url
            templateData.views = item.data.views
            templateData.tags = item.data.movie_tags

            resultsContainer.append(obj.movieResultTemplate(templateData))
            break

          case "album":
            templateData.images = item.data.photos
            templateData.name = item.data.title
            templateData.url = item.data.url
            templateData.views = item.data.views
            templateData.tags = item.data.album_tags

            resultsContainer.append(obj.albumResultTemplate(templateData))
            break
        }
      })

      obj.elem.find(".rightcol").removeClass("disabled")
      obj.requestInProgress = false

      //Пагинация. Если результатов столькое же сколько limit, значит текущая страница не последняя
      //надо кнопку Показать больше вывести и page увеличить
      if (data.length >= obj.limit) {
        obj.page += 1
        obj.showMoreElem.addClass("active")
      } else {
        obj.showMoreElem.removeClass("active")
      }

      //Обновим у foundaion компоненты
      $(document).foundation('interchange', 'reflow')
      $(document).foundation('reveal', 'reflow')

      //Если после обработки ответа у нас нету на экране результатов
      //то покажем Ничего не найдено
      let foundNothingElem = resultsContainer.nextAll(".found-nothing")
      if (resultsContainer.find(".result").length) {
        foundNothingElem.removeClass("visible")
      } else {
        foundNothingElem.addClass("visible")
      }
    }
  }

  let searchCompiler = new SearchCompiler({
    elem: $("section.main.search.index")
  })

  //Автокомплит
  class SearchAutocomplete {
    constructor(options) {
      let obj = this
      this.elem = options.elem
      this.inputElem = this.elem.find("input.query")
      this.autocompleteElem = this.elem.find(".autocomplete")
      this.cityId = this.elem.data("search-city-id")

      //Шаблон для элемента внутри блока с инпутом
      this.selectedElemTemplate = $("#search-selected-elem-template").html()
      this.selectedElemTemplate = Handlebars.compile(this.selectedElemTemplate)

      this.inputElem.click(function () {
        obj.showDropdown()
      })

      $(document).click(function (event) {
        if ($(event.target).closest("section.main.search input.query").length) return
        if ($(event.target).closest("section.main.search .autocomplete").length) return

        obj.hideDropdown()
      })

      this.inputElem.on("input", function () {
        if (obj.inputElem.val().length < 3) {
          obj.hideDropdown()
          return
        }
        obj.getResults()
      })

      this.elem.click(function (event) {
        let chosenElem = $(event.target).closest(".autocomplete .elem")
        if (chosenElem.length &&
          obj.elem[0].contains(event.target)) {
          obj.chooseElem(chosenElem)
        }
      })
    }

    chooseElem(chosenElem) {
      //При выборе любого знаения кроме конкретного заведения
      //синтезируем клик по соответствующему элементу на странице
      //пусть всю обработку произведет сборщик параметров поиска
      if (chosenElem.data("search-category") == "what" && chosenElem.data("search-key") == "place_id") {
        let elem = $(this.selectedElemTemplate({text: chosenElem.text()}))
        elem.attr({
          "data-search-category": chosenElem.data("search-category"),
          "data-search-key": chosenElem.data("search-key"),
          "data-search-value": chosenElem.data("search-value"),
          "data-search-description": chosenElem.text()
        })
        this.inputElem.before(elem)

        //После выбора конкретного заведения нужно отправить форму вручную
        this.elem.find(".search-form").submit()
      } else {
        let targetElem = this.elem.find("[data-search-option]")
          .filter(`[data-search-category='${chosenElem.data("search-category")}']`)
          .filter(`[data-search-key='${chosenElem.data("search-key")}']`)
          .filter(`[data-search-value='${chosenElem.data("search-value")}']`)

        targetElem.trigger("click")
      }

      this.hideDropdown()
      this.inputElem.val("")
    }

    showDropdown() {
      this.autocompleteElem.addClass("active")
    }

    hideDropdown() {
      this.autocompleteElem.removeClass("active")
      this.autocompleteElem.html("")
    }

    getResults() {
      let obj = this
      let url = obj.elem.data("search-autocomplete-url")
      let cityId = obj.elem.data("search-city-id")
      let searchQuery = obj.inputElem.val()

      $.getJSON(url, {city_id: cityId, search_query: searchQuery}, function (data) {
        obj.autocompleteElem.html("")
        obj.showDropdown()

        data.forEach(function (item) {
          let elem = $(`<div class="elem">${item.description}</div>`)

          let search_category
          if (item.type == "what" || item.type == "tags") {
            search_category = "what"
          } else if (item.type == "when") {
            search_category = "when"
          }

          let search_key = item.key
          if (item.type == "tags") {
            search_key = "tag_id"
          }

          let search_value = item.value

          elem.attr({
            "data-search-category": search_category,
            "data-search-key": search_key,
            "data-search-value": search_value
          })

          obj.autocompleteElem.append(elem)
        })
      })
    }
  }

  new SearchAutocomplete({
    elem: $("section.main.search.index")
  })

  //Инициализатор - формирует первичный запрос на сервер при загрузке станицы
  //и делает нужные пункты выбранными в html
  //объект инициализатора должен создаваться после объекта сборщика и формировщика,
  //т.к. инициализатор использует сборщик и формировщик при работе
  class SearchInitializer {
    constructor(options) {
      let obj = this
      obj.elem = options.elem

      //Шаблон для элемента внутри блока с инпутом
      this.selectedElemTemplate = $("#search-selected-elem-template").html()
      this.selectedElemTemplate = Handlebars.compile(this.selectedElemTemplate)

      //Формируем параметры первоначального запроса
      let parsed = $.queryStringParse(location.search).search_params
      if (parsed) {
        parsed = JSON.parse(parsed)
        parsed.common.city_id = searchCompiler.cityId
        parsed.common.page = searchCompiler.page
        parsed.common.limit = searchCompiler.limit
        if (!parsed.common.sort_by) {
          parsed.common.sort_by = searchCompiler.sortBy
        }
      } else {
        parsed = searchCompiler.compileParams()
      }

      //Теперь нужно в фильтрах выставить наши начальные параметры
      //выставим их синтезируя действия пользователя - клики по нужным элементам
      //Лайк рекомендует, для детей
      //Мы везде синтезируем событие со вторым аргументом - true
      //Это в классе сборщика означает, что мы не будем после события отправлять форму
      //мы в инициализаторе когда все необходимое выберем, сами отправим форму
      if (parsed.common.recommend) {
        obj.elem.find("[data-search-option-checkbox-recommends]")
          .attr("checked", "checked")
          .trigger("change", [true])
      }
      if (parsed.common.for_kids) {
        obj.elem.find("[data-search-option-checkbox-children]")
          .attr("checked", "checked")
          .trigger("change", [true])
      }

      //Текст
      if (parsed.common.search_query) {
        obj.elem.find(".search-form input.query").val(parsed.common.search_query)
      }

      //Экстра-параметры
      if (parsed.extra) {
        parsed.extra.forEach((item) => {
          switch (item.category) {
            case "what":
              obj.selectWhatParam(item)
              break
            case "where":
              obj.selectWhereParam(item)
              break
            case "when":
              obj.selectWhenParam(item)
              break
            case "rates":
              obj.selectRatesParam(item)
              break
            case "price":
              obj.selectPriceParam(item)
              break
          }
        })
      }

      //Теперь синтезируем отправку формы
      this.elem.find(".search-form").trigger("submit")
    }

    selectWhatParam(item) {
      if (item.key == "tag_id") {
        this.elem.find(".filter-block.tags .tags-item")
          .filter(`[data-search-value='${item.value}']`)
          .trigger("click", [true])
      } else if (item.key == "place_id") {
        let placeItem = $(this.selectedElemTemplate({text: item.description}))
        placeItem.attr({
          "data-search-category": item.category,
          "data-search-key": item.key,
          "data-search-value": item.value,
          "data-search-description": item.description
        })
        this.elem.find(".search-form input.query").before(placeItem)
      } else {
        this.selectStandartParam(item)
      }
    }

    selectWhereParam(item) {
      if (item.key == "city_area_id") {
        this.selectStandartParam(item)

      } else if (item.key == "geo") {
        //Обрабатывать геолокацию эмитацией клика - непраивльно
        //во-первых окошко вылезет, во-вторых это асинхронная операция
        //поэтому добавим элемент в строку поиска вручную
        //Шаблон для элемента внутри блока с инпутом

        let geoItem = $(this.selectedElemTemplate({text: "Рядом со мной"}))
        geoItem.attr("data-search-category", "where")
          .attr("data-search-key", "geo")
          .data({
            "search-value": item.value
          })
          .addClass("green")

        this.elem.find(".search-form input.query").before(geoItem)
        this.elem.find("[data-search-option-geo]").addClass("active")

      }
    }

    selectWhenParam(item) {
      if (item.key == "pattern") {
        this.selectStandartParam(item)
      } else if (item.key == "date") {
        this.elem.find(`[data-search-option-date-from]`)
          .val(item.value)
          .trigger("change", [true])

      } else if (item.key == "range") {
        this.elem.find(`[data-search-option-date-from]`)
          .val(item.value.from)
          .trigger("change", [true])

        this.elem.find(`[data-search-option-date-to]`)
          .val(item.value.to)
          .trigger("change", [true])

      }
    }

    selectRatesParam(item) {
      item.key = "no"
      this.selectStandartParam(item)
    }

    selectPriceParam(item) {
      this.selectStandartParam(item)
    }

    selectStandartParam(item) {
      this.elem.find(".filter-item[data-search-option]")
        .filter(`[data-search-category='${item.category}']`)
        .filter(`[data-search-key='${item.key}']`)
        .filter(`[data-search-value='${item.value}']`)
        .trigger("click", [true])
    }
  }

  new SearchInitializer({
    elem: $("section.main.search.index")
  })

  //Помогаем юзеру если он кликнул в результат, но не по ссылке
  class MisClick {
    constructor(options) {
      let obj = this
      this.elem = options.elem

      this.elem.click(function (event) {
        let targetElem = $(event.target)
        let resultElem = targetElem.closest(".result")

        if (!targetElem.closest("a").length &&
          resultElem.length &&
          obj.elem[0].contains(targetElem[0])) {
          obj.misClickHelper(resultElem)
        }
      })
    }

    misClickHelper(resultElem) {
      let link = resultElem.find(".result-description a")
      if (!link.length) return

      link[0].click()
    }
  }

  $("section.main.search.index .rightcol .results").each(function () {
    new MisClick({
      elem: $(this)
    })
  })
})
