$(document).on("ready", () => {
    class MainAlert {
        constructor(options) {
            this.elem = options.elem;

            setTimeout(() => {
                this.elem.fadeOut();
            }, 3000);
        }
    }

    $("section.main-alerts .alert-box")
        .checkComponentElem("main-alert-initialized")
        .each(function () {
            new MainAlert({
                elem: $(this)
            });
        });
});