$(document).on("ready", function () {
    if (!$("section.main.films.movie").length) {
        return;
    }

    //Блок Еще
    class More {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;

            this.elem.click(function (event) {
                if ($(event.target).closest(".more-trigger").length &&
                    obj.elem[0].contains(event.target)) {
                    event.preventDefault();
                    obj.toggleContent();
                }
            });
        }

        toggleContent() {
            this.elem.toggleClass("active");
        }
    }

    let more = new More({
        elem: $("section.main.films.movie .main-movie .more")
    });

    //Блок Команда
    class Team {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;

            this.elem.click(function(event) {
               if($(event.target).closest(".team-title").length &&
               obj.elem[0].contains(event.target)) {
                   event.preventDefault();
                   obj.toggleContent();
               }
            });
        }

        toggleContent() {
            this.elem.toggleClass("active");
        }
    }

    let team = new Team({
       elem: $("section.main.films.movie .team")
    });

});