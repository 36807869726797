$(document).on("ready", function() {
   let pageElem = $("section.main.places.category2");
    if(!pageElem.length) return;

    //Дропдаун в фильтре
    class Dropdown {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;

            this.elem.click(function (event) {
                if ($(event.target).closest(".trigger").length &&
                    obj.elem[0].contains(event.target)) {
                    event.preventDefault();

                    obj.toggleDropdown();
                }
            });

            $(document).click(function(event) {
                if(obj.elem[0].contains(event.target)) {
                    return;
                }

                obj.elem.removeClass("active");
            });

            this.elem.click(function(event) {
                let dropdownElem = $(event.target).closest(".dropdown-elem");
                if (dropdownElem.length &&
                    obj.elem[0].contains(event.target)) {
                    event.preventDefault();

                    obj.select(dropdownElem);
                }
            });
        }

        toggleDropdown() {
            this.elem.toggleClass("active");
        }

        select(dropdownElem) {
            let obj = this;
            let triggerElem = obj.elem.find(".trigger");
            let inputElem = obj.elem.find("input.input");

            triggerElem.find(".text").html(dropdownElem.find(".text").html());

            inputElem
                .val(dropdownElem.data("value"))
                .trigger("change");

            obj.elem.removeClass("active");
        }
    }

    pageElem.find(".filter .custom-select").each(function() {
        let item = new Dropdown({
            elem: $(this)
        });
    });

    //Рейтинг
    class Rating {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;

            this.hiddenFieldElem = this.elem.find("input[type=hidden]");

            //Выбрал ли пользователь значение (кликнул ли на звезду)
            this.userChosed = false;

            this.initialRating = this.hiddenFieldElem.val();

            this.stars = [];

            this.stars.push(this.elem.find(".star").eq(0));
            this.stars.push(this.elem.find(".star").eq(1));
            this.stars.push(this.elem.find(".star").eq(2));
            this.stars.push(this.elem.find(".star").eq(3));
            this.stars.push(this.elem.find(".star").eq(4));

            this.stars[0].hover(function () {
                obj.highlightStar(0);
            });

            this.stars[1].hover(function () {
                obj.highlightStar(1);
            });

            this.stars[2].hover(function () {
                obj.highlightStar(2);
            });

            this.stars[3].hover(function () {
                obj.highlightStar(3);
            });

            this.stars[4].hover(function () {
                obj.highlightStar(4);
            });

            this.elem.mouseleave(function () {
                obj.highlightStar(obj.initialRating - 1);
            });

            //Клик по звезде
            this.elem.click(function (event) {
                let star = $(event.target).closest(".star");
                if (star.length && obj.elem[0].contains(star[0])) {

                    obj.rate(star);
                }
            });
        }

        highlightStar(number, force) {
            let obj = this;

            //Если пользователь выбрал рейтинг (кликнул на звезду), то ничего не надо делать
            //но есть параметр force, который если true, то все равно подсветим звезду
            if (obj.userChosed && !force) {
                return;
            }

            obj.elem.removeClass("__1 __2 __3 __4 __5").addClass(`__${number + 1}`);
        }

        rate(starElem) {
            let obj = this;

            let rating;
            if(starElem.is(obj.stars[0])) {
                rating = 1;
            } else if(starElem.is(obj.stars[1])) {
                rating = 2;
            } else if(starElem.is(obj.stars[2])) {
                rating = 3;
            } else if(starElem.is(obj.stars[3])) {
                rating = 4
            } else if(starElem.is(obj.stars[4])) {
                rating = 5;
            }

            obj.hiddenFieldElem.val(rating);
            obj.highlightStar(rating - 1, true);
            obj.userChosed = true;
        }
    }

    pageElem.find(".filter .rating").each(function() {
        let item = new Rating({
           elem: $(this)
        });
    });
});