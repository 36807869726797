class OverviewsTileComponent {
    constructor(options) {
        let obj = this;
        this.elem = options.elem;
        this.itemElems = this.elem.find(".ct-item");

        let plugUrl = this.elem.data('plug-url') || "#";

        let plug = $(`
           <a href="${plugUrl}" class="ct-item __plug">
             <div class="ct-spacer"></div>
             <div class="ct-block">
                <div class="ct-icon"></div>
                <div class="ct-text">другие обзоры</div>
              </div>
            </a>
        `);

        let remainder = this.elem.find(".ct-item").length % 4;
        if (remainder == 1) {
            plug.addClass("__3");
        } else if (remainder == 2) {
            plug.addClass("__2");
        }

        if (remainder != 0) {
            this.elem.find(".ct-items").append(plug);
        }

        this.elem.click(function (event) {
            if (!$(event.target).closest("a").length &&
                obj.elem[0].contains(event.target)) {
                obj.misClickHelper($(event.target).closest(".ct-item"));
            }
        });
    }

    misClickHelper(itemElem) {
        let link = itemElem.find(".ct-name a");
        if (!link.length) return;

        link[0].click();
    }
}

$(document).on("page:loaded", () => {
    $(".overviews-tile-component")
        .checkComponentElem("overviews-tile-component-initialized")
        .each(function () {
            new OverviewsTileComponent({
                elem: $(this)
            });
        });
});

