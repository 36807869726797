export default async function (selector, path) {
  // Возможно стоит использовать turbolinks
  // $(document).on('turbolinks:load', async () => {
  $(document).on('ready', async () => {
    let items = $(selector).checkComponentElem()
    if (!items.length) return
    //с динамическим импортом все непросто. он анализируется до компиляции
    //путь он будет рассчитывать от директории этого файла
    //При этом вот такой вариант import(`${path}`) не работает
    //нужно чтобы часть пути уже была определена в строке
    //алисасы # @ % тоже работают. Но проще их тут не использовать
    //и вызывать функцию так mount(selector, 'common/components/lunch-teaser')
    let Component = await import(`../../${path}`)
    items.each((index, elem) => new Component.default({elem: $(elem)}))
  })
}
