class Rating2Component {
    constructor(option) {
        this.elem = option.elem;
        this.tooltipElem = this.elem.find('.ct-rc-tooltip');

        this.elem.click((event) => {
            let triggerElem = $(event.target).closest('.ct-rc-number, .ct-rc-chart, .ct-rc-tooltip-close');
            if(triggerElem.length && this.elem[0].contains(event.target)) {
                this.toggleTooltip();
            }
        });

        $(document).click((event) => {
           if(this.elem[0].contains(event.target)) {
               return;
           }

            this.tooltipElem.fadeOut('fast');
        });
    }

    toggleTooltip() {
        this.tooltipElem.fadeToggle('fast');
    }
}

$(document).on('page:loaded', () => {
    $('.rating2-component')
        .checkComponentElem('rating2-component-initialized')
        .each((index, elem) => {
            new Rating2Component({
                elem: $(elem)
            });
        });
});