class SocialModalComponent {
    constructor(options) {
        //Если в куках сказано, что показывать модальное окно не надо
        //то заканчиваем
        if (this.getCookie("dontShowSocialModal") == "yes") return;

        let obj = this;
        this.elem = options.elem;
        this.bgElem = options.elem.find(".social-modal-component-bg");
        this.elem.after(this.bgElem);
        //Показывали ли уже модальное окно
        this.fired = false;

        $(window).scroll(() => {
            if (this.fired) return;

            let halfFromTop = document.documentElement.clientHeight / 2 + pageYOffset;
            let halfPage = this.getScrollHeight() / 2;

            //Помним, что у нас событие scroll происходит не строго после скрола каждого пикселя,
            //а по внутреннему распорядку браузера. Если скролл резкий, то может через 30 пикселов
            //а если очень плавный, то может и через 0.5 пиксела
            //поэтом рассчитывать на то, что у нас halfFromTop будет равно halfPage нельзя
            //Но можно между ними разницу по модулю найти, и если она не велика, то значит сейчас пользоветль
            //примерно на середине стркницы скролит
            let delta = halfFromTop - halfPage;
            if (delta < 0) {
                delta = -1 * delta;
            }

            if (delta < 50) {
                this.fired = true;

                setTimeout(() => {
                    this.showModal();
                }, 2000);
            }
        });

        this.elem.click(function (event) {
            if ($(event.target).closest(".ct-close").length &&
                obj.elem[0].contains(event.target)) {
                obj.hideModal();
            }
        });

        this.elem.click(function (event) {
            if ($(event.target).closest(".ct-dont-show a").length &&
                obj.elem[0].contains(event.target)) {
                event.preventDefault();

                obj.hideModal();

                obj.setCookie("dontShowSocialModal", "yes", {
                    expires: 31536000,
                    path: "/"
                });
            }
        });
    }

    showModal() {
        this.elem.add(this.bgElem).fadeIn();
    }

    hideModal() {
        this.elem.add(this.bgElem).fadeOut();
    }

    getScrollHeight() {
        return Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight
        );
    }

    getCookie(name) {
        var matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    setCookie(name, value, options) {
        options = options || {};

        var expires = options.expires;

        if (typeof expires == "number" && expires) {
            var d = new Date();
            d.setTime(d.getTime() + expires * 1000);
            expires = options.expires = d;
        }
        if (expires && expires.toUTCString) {
            options.expires = expires.toUTCString();
        }

        value = encodeURIComponent(value);

        var updatedCookie = name + "=" + value;

        for (var propName in options) {
            updatedCookie += "; " + propName;
            var propValue = options[propName];
            if (propValue !== true) {
                updatedCookie += "=" + propValue;
            }
        }

        document.cookie = updatedCookie;
    }
}

$(document).on("page:loaded", () => {
    $(".social-modal-component")
        .checkComponentElem("social-modal-component-initialized")
        .each(function () {
            new SocialModalComponent({
                elem: $(this)
            });
        });
});