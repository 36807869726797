class AdjustBackgroundPositionComponent {
    constructor(options) {
        this.elem = options.elem;
        this.shift = this.elem.data("background-vertical-shift") || 0;
        //Это ориентир для расчетов
        this.orientingPoint = 1000;

        this.setBackgroundPosition();

        $(window).resize(() => {
            this.setBackgroundPosition();
        });
    }

    setBackgroundPosition() {
        let elemWidth = this.elem.width();
        let elemHeight = this.elem.height();
        let ratio = elemWidth / elemHeight;

        let backgroundY = this.shift * elemWidth / this.orientingPoint;
        backgroundY = Math.round(backgroundY);

        //Только на более-менее вытянутом блоке имеет смысл это делать
        //а вытянут он примерно когда его пропорция больше 1.9
        if (ratio > 1.9) {
            this.elem.css({
                "background-position": `50% -${backgroundY}px`,
                "background-size": "100% auto",
                "background-repeat": "no-repeat"
            });
        } else {
            this.elem.css({
                "background-position": "50% 50%",
                "background-size": "cover"
            });
        }
    }
}

$(document).on("page:loaded", () => {
    $(".adjust-background-position-component")
        .checkComponentElem("adjust-background-position-component-initialized")
        .each(function () {
            new AdjustBackgroundPositionComponent({
                elem: $(this)
            });
        });
});