//Заглушка для плитки событий
Handlebars.registerHelper("events-tile-plug", function (data, plug_url) {
    let plug = $(`
           <a href="${plug_url}" class="ct-item __plug">
             <div class="ct-spacer"></div>
             <div class="ct-block">
                <div class="ct-icon"></div>
                <div class="ct-text">найти события</div>
              </div>
            </a>
        `);

    let remainder = data.length % 4;
    if(remainder == 1) {
        plug.addClass("__3");
    } else if (remainder == 2) {
        plug.addClass("__2");
    }

    if(remainder != 0) {
        return new Handlebars.SafeString(plug[0].outerHTML);
    }
});

//Заглушка для плитки заведений
Handlebars.registerHelper("places-tile-plug", function(data, plug_url) {
    let plug = $(`
           <a href="${plug_url}" class="ct-item __plug">
             <div class="ct-spacer"></div>
             <div class="ct-block">
                <div class="ct-icon"></div>
                <div class="ct-text">найти места</div>
              </div>
            </a>
        `);

    let remainder = data.length % 6;
    if(remainder == 1) {
        plug.addClass("__2");
    } else if (remainder == 2) {
        plug.addClass("__large-up");
    } else if (remainder == 3) {
        plug.addClass("__medium-down");
    } else if (remainder == 4) {
        plug.addClass("__2 __large-up");
    }

    if(remainder != 0) {
        return new Handlebars.SafeString(plug[0].outerHTML);
    }
});

//Рандомный класс градиента
Handlebars.registerHelper("random-gradient-class", function () {
    function randomInteger(min, max) {
        let rand = min + Math.random() * (max + 1 - min);
        rand = Math.floor(rand);
        return rand;
    }

    let gradients = [
        "__light-green", "__green", "__dark-green",
        "__dark-violet", "__red", "__violet",
        "__blue", "__light-blue", "__dark-blue"
    ];

    let randomIndex = randomInteger(0, 8);
    let randomGradient = gradients[randomIndex];

    return new Handlebars.SafeString(randomGradient);
});

//Вывод нужного количества звезд у плитки заведений
Handlebars.registerHelper("places-tile-stars", function () {
    let html;

    switch (this.rates) {
        case 1:
            html = `<div class="ct-star"></div>`;
            break;
        case 2:
            html = `<div class="ct-star"></div>
                    <div class="ct-star"></div>`;
            break;
        case 3:
            html = `<div class="ct-star"></div>
                    <div class="ct-star"></div>
                    <div class="ct-star"></div>`;
            break;
        case 4:
            html = `<div class="ct-star"></div>
                    <div class="ct-star"></div>
                    <div class="ct-star"></div>
                    <div class="ct-star"></div>`;
            break;
        case 5:
            html = `<div class="ct-star"></div>
                    <div class="ct-star"></div>
                    <div class="ct-star"></div>
                    <div class="ct-star"></div>
                    <div class="ct-star"></div>`;
            break;
    }

    return new Handlebars.SafeString(html);
});

//Вывод нужного количества звезд
Handlebars.registerHelper("rating-stars", function (params) {
    let html;

    switch (params.hash.rating) {
        case 1:
            html = `<div class="${params.hash.starClass}"></div>`;
            break;
        case 2:
            html = `<div class="${params.hash.starClass}"></div>
                    <div class="${params.hash.starClass}"></div>`;
            break;
        case 3:
            html = `<div class="${params.hash.starClass}"></div>
                    <div class="${params.hash.starClass}"></div>
                    <div class="${params.hash.starClass}"></div>`;
            break;
        case 4:
            html = `<div class="${params.hash.starClass}"></div>
                    <div class="${params.hash.starClass}"></div>
                    <div class="${params.hash.starClass}"></div>
                    <div class="${params.hash.starClass}"></div>`;
            break;
        case 5:
            html = `<div class="${params.hash.starClass}"></div>
                    <div class="${params.hash.starClass}"></div>
                    <div class="${params.hash.starClass}"></div>
                    <div class="${params.hash.starClass}"></div>
                    <div class="${params.hash.starClass}"></div>`;
            break;
    }

    return new Handlebars.SafeString(html);
});

//На главной нужно вывести подходящий класс у иконки выгоды
Handlebars.registerHelper("index_benefits_icon_class", function () {
    let iconClass;

    if (this.kind == 1) {
        iconClass = "kupon";
    } else if (this.kind == 2) {
        iconClass = "discount";
    } else if (this.kind == 3) {
        iconClass = "gift";
    }

    return new Handlebars.SafeString(iconClass);
});