$(document).on("ready", () => {
    //Кнопка Для скрола наверх
    class BackToTopButton {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;

            //На странице поиска мы обычную кнопку не инициализуем
            if($(".main.search.index").length && !this.elem.is(".back-to-top-page.back-to-search")) {
                return;
            }

            this.elem.click(function (event) {
                event.preventDefault();

                $('html, body').animate({
                    scrollTop: 0
                }, 800);
            });

            $(document).scroll(function () {
                obj.toggleElem();
            });

            obj.toggleElem();
        }

        toggleElem() {
            let obj = this;
            if (pageYOffset > 2500) {
                obj.elem.addClass("visible");
            } else {
                obj.elem.removeClass("visible");
            }
        }
    }

    $(".back-to-top-page.back-to-search, .back-to-top-page.back-to-top-btn")
        .checkComponentElem("back-to-top-page-initialized")
        .each(function () {
            new BackToTopButton({
                elem: $(this)
            });
        });
});