$(document).on("ready", function () {
    if (!$("section.main.films").length) {
        return;
    }

    //Дропдаун в фильтре
    class Dropdown {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;

            this.elem.click(function (event) {
                if ($(event.target).closest(".trigger").length &&
                    obj.elem[0].contains(event.target)) {
                    event.preventDefault();

                    obj.toggleDropdown();
                }
            });

            $(document).click(function(event) {
                if(obj.elem[0].contains(event.target)) {
                    return;
                }

                obj.elem.removeClass("active");
            });

            this.elem.click(function(event) {
                let dropdownElem = $(event.target).closest(".dropdown-elem");
                if (dropdownElem.length &&
                    obj.elem[0].contains(event.target)) {
                    event.preventDefault();

                    obj.select(dropdownElem);
                }
            });
        }

        toggleDropdown() {
            this.elem.toggleClass("active");
        }

        select(dropdownElem) {
            let obj = this;
            let triggerElem = obj.elem.find(".trigger");
            let inputElem = obj.elem.find("input.input");

            triggerElem.find(".text").html(dropdownElem.find(".text").html());
            triggerElem.find("small").html(dropdownElem.find("small").html());

            inputElem
                .val(dropdownElem.data("value"))
                .trigger("change");

            obj.elem.removeClass("active");
        }
    }

    let dropdowns = [];
    $("section.main.films .search-filter .date-type-container .elem").each(function() {
        let dropdown = new Dropdown({
            elem: $(this)
        });

        dropdowns.push(dropdown);
    });

    //Отправка формы-фильтра при изменении любого из параметров
    class SearchFilterSubmit {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;

            this.elem.change(function() {
               obj.triggerSubmit();
            });
        }

        triggerSubmit() {
            this.elem[0].submit();
        }
    }

    let searchFiltersSubmit = [];

    $("section.main.films .search-filter").each(function() {
       let item = new SearchFilterSubmit({
           elem: $(this)
       });

        searchFiltersSubmit.push(item);
    });
});