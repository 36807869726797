//Мнения экспертов на главной
class PlacesTileComponent {
    constructor(options) {
        let obj = this;
        this.elem = options.elem;
        this.itemElems = this.elem.find(".ct-item");

        let plugColorClass = this.elem.data('plug-color-class') || "";
        let plugUrl = this.elem.data('plug-url') || "#";

        let plug = $(`
           <a href="${plugUrl}" class="ct-item __plug ${plugColorClass}">
             <div class="ct-spacer"></div>
             <div class="ct-block">
                <div class="ct-icon"></div>
                <div class="ct-text">найти места</div>
              </div>
            </a>
        `);

        let remainder = this.elem.find(".ct-item").length % 6;
        if (remainder == 1) {
            plug.addClass("__2");
        } else if (remainder == 2) {
            plug.addClass("__large-up");
        } else if (remainder == 3) {
            plug.addClass("__medium-down");
        } else if (remainder == 4) {
            plug.addClass("__2 __large-up");
        }

        if (remainder != 0) {
            this.elem.append(plug);
        }

        this.elem.click(function (event) {
            if (!$(event.target).closest("a").length &&
                obj.elem[0].contains(event.target)) {
                obj.misClickHelper($(event.target).closest(".ct-item"));
            }
        });
    }

    misClickHelper(itemElem) {
        let link = itemElem.find(".ct-name a");
        if (!link.length) return;

        link[0].click();
    }
}

$(document).on("page:loaded", () => {
    $(".places-tile-component")
        .checkComponentElem("places-tile-component-initialized")
        .each(function () {
            new PlacesTileComponent({
                elem: $(this)
            });

        });
});

