class MinisGalleryComponent {
    constructor(options) {
        let obj = this;
        this.elem = options.elem;
        this.galleryModal = this.elem.find(".reveal-modal");

        let gallery;

        $(document).on("opened.fndtn.reveal", "[data-reveal]", function () {
            if ($(this).is(obj.galleryModal) && !gallery) {
                //Инициализировать галерею можно только в том случаи, если она видна
                //специально отслеживается событие окончания открытия окна, чтобы галерея уже
                //точно была польностью видна
                //Инициализируем галерею только один раз
                gallery = new GalleryComponent({
                    elem: $(this).find(".gallery")
                });

                //Прокрутим на выбранный слайд
                gallery.moveToSlide($(this).data("index"), true, 1);
            } else if ($(this).is(obj.galleryModal) && gallery) {
                //Галерея уже инициазирована. Просто прокрутим ее на нужное место
                //специально отслеживается событие окончания открытия окна, чтобы галерея уже точно была видна
                //Также необходимо "перенастроить" галерею (setup). Дело в том, что когда модальное окно закрывается
                //и потом пользователь меняет размер экрана, то в момент этого изменения размера плагин меняет размер
                //галереи, но окно не видно и размер галереи становится 0. Поэтому если не "перенастроить" ее
                //когда она теперь стала видна, то она может не отобразиться
                gallery.setup();
                gallery.moveToSlide($(this).data("index"), true, 1);
            }
        });

        //По клику на миниатюре на странице установим дата-атрибут активного слайда
        this.elem.find("> .minis a").click(function () {
            let index = $(this).data("index");
            obj.galleryModal.data("index", index);
        });
    }
}

$(document).on("page:loaded", function () {
    $(".minis-gallery-component")
        .checkComponentElem("minis-gallery-component-initialized")
        .each(function () {
            new MinisGalleryComponent({
                elem: $(this)
            });
        });
});