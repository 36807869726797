$(document).on("ready", function() {
    if (!$("section.main.index.index-page").length) {
        return;
    }

    // Т.к изменили дизайн, галереи фото-сессий, фото-отчетов отключили

    //Галереи фото-отчетов и фото-сессий инициализируем и управляем  руками
    //потому что они то показаны, то скрыты, а SwipeJS как известно глючит в таких случаях
    // let reportsGallery;
    // $("section.main.index.index-page #photo-video-reports .gallery-component").each(function() {
    //     reportsGallery = new GalleryComponent({
    //         elem: $(this)
    //     });
    // });
    //
    // let sessionsGallery;
    // $("section.main.index.index-page #photo-video-sessions .gallery-component").each(function() {
    //     sessionsGallery = new GalleryComponent({
    //         elem: $(this)
    //     });
    // });
    //
    // //По клику на табы перезагружаем галереи
    // let tabs = $("section.main.index.index-page .tabs-component-tabs .ct-tab")
    // tabs.click(function() {
    //     setTimeout(function() {
    //         if(reportsGallery) reportsGallery.setup();
    //         if(sessionsGallery) sessionsGallery.setup();
    //     }, 0);
    // });

    // Т.к изменили дизайн, теперь в табах используется youtube_thumb_component
    // let videoIsVisible = false;
    // //По клику на табы нужно остановить/заново_инициализировать видео
    // tabs.click(function() {
    //     let video = $("#photo-video-video .flex-video");
    //     let iframe = $("iframe", video);
    //
    //     //Клик по табу видео
    //     let data_id = $(this).data("id");
    //     if(data_id == "photo-video-video") {
    //         videoIsVisible = true;
    //
    //         let content = $("#" + data_id);
    //         if(content.is(":visible")) {
    //            return;
    //         }
    //
    //         if (iframe.length > 0) {
    //             let data_src = iframe.attr('data-src');
    //             if (typeof data_src === 'string') {
    //                 iframe[0].src = iframe.attr('data-src');
    //             } else {
    //                 let src = iframe[0].src;
    //                 iframe[0].src = undefined;
    //                 iframe[0].src = src;
    //             }
    //         }
    //     } else {
    //         //Клик по другим табам
    //         if (iframe.length > 0 && videoIsVisible) {
    //             iframe.attr('data-src', iframe[0].src);
    //             iframe.attr('src', iframe.attr('src'));
    //         }
    //
    //         videoIsVisible = false;
    //     }
    // });

    //Галерея прошедших событий
    class PastEventsGallery {
        constructor(options) {
            //return;
            let obj = this;
            this.elem = options.elem;

            $(this.elem).cycle({
                slides: "article",
                timeout: 15000,
                pauseOnHover: true,
                autoHeight: "calc",
                prev: ".prev",
                next: ".next",
                //Пока отключили автоматическое листание
                paused: true
            });

            this.elem.click(function(event) {
                if($(event.target).closest(".prev, .next").length &&
                    obj.elem[0].contains(event.target)) {
                    obj.stopSlideshow();
                }
            });
        }

        stopSlideshow() {
            this.elem.cycle("pause");
        }
    }

    $("section.main.index .past-events .gallery-list").each(function() {
       let pastEventsGallery = new PastEventsGallery({
           elem: $(this)
       });
    });
});
