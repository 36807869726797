export default class ShareComponent {
    constructor(options) {
        let obj = this;
        this.elem = options.elem;
        this.inviteELem = options.elem.find(".share-component-invite");
        //Переносим inviteElem в конец body. Это очень важно, потому что основной элемент может
        //быть скрыт, а банер-приглашение (inviteElem) нужно в это время показывать
        $("body").append(this.inviteELem);

        this.withSelection = this.elem.data("with-selection");

        this.elem.click(function (event) {
            let link = $(event.target).closest(".ct-link");
            if (link.length && obj.elem[0].contains(event.target)) {
                event.preventDefault();

                if (link.hasClass("__vk")) {
                    obj.showVk();
                } else if (link.hasClass("__fb")) {
                    obj.showFb();
                } else if(link.hasClass('__ok')) {
                    obj.showOd();
                } else if(link.hasClass('__twitter')) {
                    obj.showTwitter();
                }
            }
        });

        this.inviteELem.click(function (event) {
            if ($(event.target).closest(".ct-close").length &&
                obj.inviteELem[0].contains(event.target)) {
                obj.inviteELem.removeClass("__visible");
            }
        });

    }

    showVk() {
        let urlOptions = {};
        urlOptions.url = this.elem.data('url') || location.href;

        let selection = window.getSelection().toString();
        selection = $.trim(selection);

        if (this.withSelection && selection) {
            urlOptions.url = this.getUrlWithShareTitle(selection);
        }

        urlOptions = $.queryStringStringify(urlOptions);
        let url = `https://vk.com/share.php?${urlOptions}`;

        let options = {
            width: 650,
            height: 540,
            url: url,
            social: "vk"
        };

        this.openPopup(options);
    }

    showFb() {
        let urlOptions = {};
        urlOptions.u = this.elem.data('url') || location.href;

        let selection = window.getSelection().toString();
        selection = $.trim(selection);
        if (this.withSelection && selection) {
            urlOptions.u = this.getUrlWithShareTitle(selection);
        }

        urlOptions = $.queryStringStringify(urlOptions);
        let url = `https://www.facebook.com/sharer.php?${urlOptions}`;

        let options = {
            width: 800,
            height: 680,
            url: url,
            social: "fb"
        };

        this.openPopup(options);
    }

    showTwitter() {
        let urlOptions = $.queryStringStringify({
            url: this.elem.data('url') || location.href
        });

        let options = {
            width: 560,
            height: 480,
            url: `https://twitter.com/intent/tweet?${urlOptions}`,
        };

        this.openPopup(options);
    }

    showOd() {
        let urlOptions = $.queryStringStringify({
            "st.cmd": "WidgetSharePreview",
            "st.shareUrl": this.elem.data('url') || location.href
        });

        let options = {
            width: 800,
            height: 570,
            url: `https://connect.ok.ru/dk?${urlOptions}`
        };

        this.openPopup(options);
    }


    getUrlWithShareTitle(shareTitle) {
        let url = `${location.origin}${location.pathname}`;

        if (location.search) {
            url += `${location.search}&share_title=${$.queryStringStrictUriEncode(shareTitle)}`;
        } else {
            url += `?share_title=${$.queryStringStrictUriEncode(shareTitle)}`;
        }

        return url;
    }

    openPopup(options) {
        let obj = this;
        this.inviteELem.removeClass("__visible");

        let left = screen.width / 2 - 325;
        let top = screen.height / 2 - 270;
        let win = window.open(options.url, "vk", `width=${options.width},height=${options.height},left=${left},top=${top},resizable=yes,scrollbars=yes`);


        //С событием onload и onbeforeunload с новым окном происходят странные вещи
        //поэтому лучше на них не надеятся. Будем лучше проверять свойство win.closed
        //это надежно
        let timerId;
        timerId = setInterval(function () {
            if (win.closed) {
                clearInterval(timerId);

                obj.inviteELem.addClass("__visible");
                obj.inviteELem.find(".ct-group-link").hide();

                if (options.social == "vk") {
                    obj.inviteELem.find(".ct-group-link.__vk").show();
                } else if (options.social == "fb") {
                    obj.inviteELem.find(".ct-group-link.__fb").show();
                }
            }
        }, 400);
    }
}

$(document).on("page:loaded", () => {
    $(".share-component")
        .checkComponentElem("share-component-initialized")
        .each(function () {
            new ShareComponent({
                elem: $(this)
            });
        });
});