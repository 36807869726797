import Vue from 'vue'

let url = url => `/api/${url}.json`
Vue.prototype.$city = $('html').data('city')

Vue.prototype.$api = {
  events: url(`main_page/${Vue.prototype.$city}/events`),
  mainEvents: url(`main_page/${Vue.prototype.$city}/main_block`),
  mainNews: url(`main_page/${Vue.prototype.$city}/main_block/news`),
  themeOfDay: url(`main_page/${Vue.prototype.$city}/day_theme`),
}
