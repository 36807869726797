$(document).on("ready", function() {

  if (!$("section.main.people.index").length) {
      return;
  }

  // Скрываем поиск
  $(".main-search-form-container").hide();

  //  Пока что отключили карусель
   return;

   let sectionELem = $("section.main.people.index");

    if(!sectionELem.length) return;

    class Gallery {
        constructor(options) {
            let obj = this;
            this.elem = options.elem;
            this.root = this.elem.find(".root");

            this.root.on('jcarousel:reload jcarousel:create', function () {
                let carousel = $(this),
                    width = carousel.innerWidth();

                carousel.jcarousel('items').css('width', Math.ceil(width) + 'px');
            }).jcarousel({
                wrap: "circular",
                transitions: true,
                animation: {
                    duration: 700
                }
            });

            this.elem.find(".control.left").jcarouselControl({
                target: '-=1'
            });

            this.elem.find(".control.right").jcarouselControl({
                target: '+=1'
            });

            this.elem.find(".pagination").on('jcarouselpagination:active', 'a', function() {
                $(this).addClass('active');
            }).on('jcarouselpagination:inactive', 'a', function() {
                $(this).removeClass('active');
            }).jcarouselPagination();
        }
    }

    sectionELem.find(".photo-block .gallery").each(function() {
        let gallery = new Gallery({
            elem: $(this)
        });
    })
});
