class AjaxLoadComponent {
    constructor(options) {
        let obj = this;
        this.elem = options.elem;
        this.trigger = this.elem.find(".ct-ajax-trigger");
        this.container = this.elem.find(".ct-ajax-container");
        this.apiURL = this.elem.data("api-url");
        this.apiParams = this.elem.data("api-params");
        this.templateId = this.elem.data("template-id");
        this.switch_tab = this.elem.data("switch-tab");

        this.last_items = false;

        //Запросы могут быть с пагинацией и без
        if (this.elem.data("pagination")) {
            this.pagination = true;
            this.page = 2;
        }

        //Бывает так, что нужно сделать только один запрос на сервер
        if (this.elem.data("single-request")) {
            this.singleRequest = true;
            this.fired = false;
        }

        //Клик по тригеру
        this.elem.click(function (event) {
            if ($(event.target).closest(".ct-ajax-trigger").length
                && obj.elem[0].contains(event.target)) {

                event.preventDefault();
                if (obj.switch_tab && obj.last_items) {
                    //TODO использование подобного функционала когда на странице будет
                    //несколько экземпляров компонента с таким функционалом вызовет проблемы
                    //пока такой ситуации нет
                    let id = obj.elem.attr('id');
                    $(`.ct-tab[data-id='${id}']`).next().trigger('click');
                    $('html, body').scrollTop($('.tabs-component-tabs').offset().top);
                } else {
                    obj.loadData();
                }
            }
        });
    }

    loadData() {
        let obj = this;

        if (obj.trigger.hasClass("__disabled")) {
            return;
        }

        if (obj.singleRequest && obj.fired) {
            return;
        }

        //Блокируем триггер на время запроса
        obj.blockTrigger();

        //Если у нас есть пагинация, то ее нужно передать на сервер
        if (obj.pagination) {
            obj.apiParams.page = obj.page;
        }

        $.getJSON(obj.apiURL, obj.apiParams, function (data) {
            if (!obj.template) {
                obj.template = $('#' + obj.templateId).html();
                obj.template = Handlebars.compile(obj.template);
            }

            obj.container.append(obj.template({
                data: data
            }));

            //Снимем блокировку с тригера
            obj.unblockTrigger();

            //Увеличим на 1 пагинацию, если она предусмотрена
            if (obj.pagination) {
                obj.page += 1;
            }

            //Обновим у foundaion компоненты
            $(document).foundation('interchange', 'reflow');
            $(document).foundation('equalizer', 'reflow');

            if (obj.singleRequest) {
                obj.fired = true;
            }

            //Параметр last_page - особенный. Если он = true, то нужно убрать триггер
            //со страницы
            //Для некоторых компонент нужен функционал смены таба на следующий после того, как получили последние элементы
            if (data.last_page) {
                if (obj.switch_tab) {
                    obj.last_items = true;
                } else {
                    obj.hideTrigger();
                }
            }
        });
    }

    blockTrigger() {
        let obj = this;
        obj.trigger.addClass("__disabled").css("opacity", ".6");
    }

    unblockTrigger() {
        let obj = this;
        obj.trigger.removeClass("__disabled").css("opacity", "1");
    }

    hideTrigger() {
        let obj = this;

        if (obj.trigger.data("hide-with-parent")) {
            obj.trigger.parent().hide();
        } else {
            obj.trigger.hide();
        }
    }
}

$(document).on("ready", () => {
    $(".ajax-load-component")
        .checkComponentElem("ajax-load-component-initialized")
        .each(function () {
            new AjaxLoadComponent({
                elem: $(this)
            });
        });
});
