//Миниатюра ютуб
class YouTubeThumbComponent {
    constructor(options) {
        this.elem = options.elem;

        let iframeElem = this.elem.find(".reveal-modal iframe");
        let iframeSrc = iframeElem.attr("src");
        let videoId = iframeSrc.match(/embed\/(.+)/)[1];

        this.elem.css("background-image", `url('https://img.youtube.com/vi/${videoId}/0.jpg')`);
    }
}

$(document).on("page:loaded", () => {
    $(".youtube-thumb-component")
        .checkComponentElem("youtube-thumb-component-initialized")
        .each(function () {
            new YouTubeThumbComponent({
                elem: $(this)
            });
        });
});