class MisclickComponent {
    constructor(options) {
        this.elem = options.elem;
        this.titleElem = this.elem.find('.ct-mc-title');

        this.elem.click((event) => {
            if($(event.target).closest("a").length) {
                return;
            }

            if(!this.titleElem.length) {
                return;
            }

            this.titleElem[0].click();
        });
    }
}

$(document).on("page:loaded", () => {
    $(".misclick-component")
        .checkComponentElem("misclick-component-initialized")
        .each((index, elem) => {
            new MisclickComponent({
                elem: $(elem)
            });
        });
});
